import React, { useState } from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import PageTitle from '../../../components/common/PageTitle';
import SearchField from '../../../components/common/SearchField';
import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../../components/common/AppContainersLib';
import SurveyTable from './SurveyTable';
import SurveyDeleteModal from './SurveyDeleteModal';

const SurveyListView = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const { path } = useRouteMatch();

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  return (
    <React.Fragment>
      <Route path={`${path}/delete/:id`}>
        <SurveyDeleteModal />
      </Route>
      <Route path={`${path}`}>
        <PageWithTableContainer>
          <PageTitle title="SURVEY LIST" />
          <ListViewContainer>
            <ControlsContainer>
              <Box width={200}>
                <SearchField
                  searchKeyword={searchKeyword}
                  onSearchKeywordChange={handleSearch}
                />
              </Box>
            </ControlsContainer>
            <SurveyTable searchKeyword={searchKeyword} rowsPerPage={10} />
          </ListViewContainer>
        </PageWithTableContainer>
      </Route>
    </React.Fragment>
  );
};

export default SurveyListView;
