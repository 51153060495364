import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
// import NotifIcon from '@mui/icons-material/NotificationsOutlined';
// import FlagIcon from '@mui/icons-material/OutlinedFlag';
// import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AccountMenu from '../Profile/AccountMenu';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../store/system/actions';

const AppHeader = () => {
  const dispatch = useDispatch();

  const handleSidebarToggle = () => dispatch(toggleSidebar());

  return (
    <AppBar elevation={0} sx={{ bgcolor: 'white' }}>
      <Toolbar variant="dense" sx={{ display: 'flex' }}>
        <IconButton
          edge="start"
          color="primary"
          aria-label="menu-toggler"
          sx={{ mr: 2 }}
          onClick={handleSidebarToggle}>
          <MenuIcon />
        </IconButton>
        <Stack direction="row" ml="auto" spacing={1}>
          {/* <IconButton edge="start" color="primary" aria-label="notifications">
            <NotifIcon />
          </IconButton>
          <IconButton edge="start" color="primary" aria-label="flag">
            <FlagIcon />
          </IconButton> */}
          <AccountMenu />
          {/* <IconButton edge="start" color="primary" aria-label="settings">
            <SettingsIcon />
          </IconButton> */}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
