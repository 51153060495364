import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableColumn } from '../../../utils/types';

const faqColumns: TableColumn[] = [
  {
    id: 'index',
    label: 'FAQ No.',
    property: 'index',
    sortable: true,
    width: 10
  },
  {
    id: 'question',
    label: 'Question',
    property: 'question',
    sortable: true
  },
  {
    id: 'answer',
    label: 'Answer',
    property: 'answer',
    sortable: true
  },
  {
    id: 'category',
    label: 'Category',
    property: 'category',
    sortable: true
  },
  {
    id: 'actions',
    label: 'Action',
    key: 'faq-list-actions',
    sortable: false,
    content: (item: any) => (
      <ButtonGroup
        disableElevation
        aria-label="faq list actions"
        size="small"
        sx={{ overflow: 'hidden' }}>
        <Button
          color="info"
          sx={{
            paddingX: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          component={Link}
          to={`/library/faq/edit/${item.id}`}>
          <EditIcon fontSize="small" />
        </Button>
        <Button
          color="error"
          sx={{
            paddingX: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          component={Link}
          to={`/library/faq/delete/${item.id}`}>
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    )
  }
];

export default faqColumns;

// eslint-disable-next-line no-lone-blocks
{
  /* <IconButton
  component={Link}
  sx={{
    bgcolor: (theme) => theme.palette.error.main,
    color: 'white',
    width: 32,
    height: 32,
    borderRadius: '3px',
    '&:hover': {
      bgcolor: (theme) => theme.palette.error.main
    }
  }}
  to={`/library/faq/delete/${item.id}`}>
  <DeleteIcon fontSize="small" />
</IconButton>; */
}
