import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import logo from '../../assets/images/ramsay-logo.png';

const StyledImageForLogo = styled('img')(({ theme }) => ({
  width: '65%',
  height: '85%',
  objectFit: 'cover',
  backfaceVisibility: 'hidden',
  transform: 'translateZ(0)',
  verticalAlign: 'middle'
}));

const AppLogo = () => {
  return (
    <Box display="flex" justifyContent="center" py={3} mb={2}>
      <StyledImageForLogo src={logo} alt="Ramsay" height={48} />
    </Box>
  );
};

export default AppLogo;
