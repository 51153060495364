import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableColumn } from '../../../utils/types';
import { Feedback } from '../../../store/library/feedback/types';
import { Link } from 'react-router-dom';

const libFeedbackColumns: TableColumn[] = [
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  {
    id: 'question',
    label: 'Question',
    property: 'question',
    sortable: true
  },
  {
    id: 'actions',
    label: 'Actions',
    key: 'lib-feedback-actions',
    sortable: false,
    content: (item: Feedback) => (
      <ButtonGroup
        disableElevation
        aria-label="library feedback actions"
        size="small"
        sx={{ overflow: 'hidden' }}>
        <Button
          color="info"
          sx={{
            paddingX: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          component={Link}
          to={`/library/feedback/edit/${item.id}`}>
          <EditIcon fontSize="small" />
        </Button>
        <Button
          color="error"
          sx={{
            paddingX: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          component={Link}
          to={`/library/feedback/delete/${item.id}`}>
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    )
  }
];

export default libFeedbackColumns;
