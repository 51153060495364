import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface LabelWithIconProps {
  icon?: ReactNode;
  label: string;
}

const LabelWithIcon = (props: LabelWithIconProps) => {
  const { icon, label } = props;
  return (
    <Stack direction="row" alignItems="flex-end" spacing={1}>
      {icon && icon}
      <Typography sx={{ lineHeight: 1.2 }}>{label}</Typography>
    </Stack>
  );
};

export default LabelWithIcon;
