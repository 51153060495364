import Box from '@mui/material/Box';
import DashboardStatBox from './DashboardStatBox';
import useResourcesCount from '../../hooks/useResourcesCount';

const ContactStatSection = () => {
  const count = useResourcesCount();

  return (
    <Box display="grid" gridTemplateColumns="repeat(3, 300px)" gap={3}>
      <DashboardStatBox
        value={count.registeredContacts}
        label="Registered"
        type="registered"
      />
      <DashboardStatBox
        value={count.contactedContacts}
        label="Contacted"
        type="contacted"
      />
    </Box>
  );
};

export default ContactStatSection;
