import { AppError, Status } from '../../system/types';

// STATE
export interface FAQBase {
  question: string;
  answer: string;
  source: 'FAQ' | 'Chatbot';
  category: string;
}

export interface IFAQ extends FAQBase {
  id: string;
  faqNo: string;
  dateCreated: Date | string;
  dateUpdated: Date | string;
  userId: string;
  username: string;
}

export interface FAQState {
  data: IFAQ[];
  status: Status;
  error: AppError;
}

export const faqFormInitialValues: FAQBase = {
  question: '',
  answer: '',
  source: 'FAQ',
  category: 'Using the Calculator'
};

// ACTION TYPES
export const SET_FAQ_DATA = 'faq/data/set';
export const SET_FAQ_STATUS = 'faq/status/set';
export const ADD_FAQ = 'faq/data/add';
export const UPDATE_FAQ = 'faq/data/update';
export const DELETE_FAQ = 'faq/data/delete';

export interface SetFAQStatusAction {
  type: typeof SET_FAQ_STATUS;
  payload: Status;
}

export interface SetFAQDataAction {
  type: typeof SET_FAQ_DATA;
  payload: IFAQ[];
}

export interface AddFAQAction {
  type: typeof ADD_FAQ;
  payload: { newFAQ: IFAQ };
}

export interface UpdateFAQAction {
  type: typeof UPDATE_FAQ;
  payload: { id: string; updatedFAQ: FAQBase };
}

export interface DeleteFAQAction {
  type: typeof DELETE_FAQ;
  payload: { id: string };
}

export type FAQAction =
  | SetFAQStatusAction
  | SetFAQDataAction
  | AddFAQAction
  | UpdateFAQAction
  | DeleteFAQAction;
