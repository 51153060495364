import {
  FAQAction,
  FAQState,
  SET_FAQ_DATA,
  SET_FAQ_STATUS,
  ADD_FAQ,
  DELETE_FAQ,
  UPDATE_FAQ
} from './types';

const initialState: FAQState = {
  data: [],
  status: 'idle',
  error: undefined
};

export default function reducer(
  state = initialState,
  action: FAQAction
): FAQState {
  switch (action.type) {
    case SET_FAQ_STATUS:
      return { ...state, status: action.payload };
    case SET_FAQ_DATA:
      return { ...state, data: action.payload, status: 'idle' };
    case ADD_FAQ:
      return {
        ...state,
        data: [action.payload.newFAQ, ...state.data],
        status: 'idle'
      };
    case UPDATE_FAQ:
      return {
        ...state,
        data: state.data.map((i) =>
          i.id === action.payload.id
            ? { ...i, ...action.payload.updatedFAQ }
            : i
        ),
        status: 'idle'
      };
    case DELETE_FAQ:
      return {
        ...state,
        data: state.data.filter((i) => i.id !== action.payload.id),
        status: 'idle'
      };
    default:
      return state;
  }
}
