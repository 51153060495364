import React from 'react';
import _ from 'lodash';
import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { appColors } from '../../theme';

type AMIAlertProps = {
  message?: string;
  type?: 'success' | 'error' | 'warning';
};

const colorMap = {
  success: appColors.success,
  error: appColors.error,
  warning: appColors.warning
};

const AMIAlert = ({
  message,
  type = 'success',
  ...snackProps
}: AMIAlertProps & SnackbarProps): JSX.Element => {
  const bgcolor = colorMap[type];
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      key="topright"
      {...snackProps}>
      <Alert
        variant="filled"
        sx={{ bgcolor, paddingY: '10px' }}
        severity={type}
        elevation={6}>
        {_.capitalize(message)}
      </Alert>
    </Snackbar>
  );
};

export default AMIAlert;
