import {
  CalcFeebackAction,
  FeedbackAnswer,
  SET_CALC_FEEDBACK_DATA
} from './types';

export const setCalcFeedbackData = (
  feedbackData: FeedbackAnswer[]
): CalcFeebackAction => ({
  type: SET_CALC_FEEDBACK_DATA,
  payload: { feedbackData }
});
