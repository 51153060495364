import { isArray } from 'lodash';
import { AppThunk } from '..';
import {
  forgotPasswordAxiosInstance,
  loginAxiosInstance
} from '../../utils/api';
import { apiEndpoints } from '../../utils/constants';
import errorHandler from '../../utils/errorHandler';
import { showSuccessSnackbar } from '../system/actions';
import {
  AuthAction,
  AuthUser,
  CALL_AUTH,
  CALL_AUTH_FAILED,
  CALL_AUTH_SUCCESS,
  FORGOT_PASSWORD,
  SET_AUTH_USER,
  LOG_OUT_USER,
  Token
} from './types';

export const callAuth = (): AuthAction => {
  return {
    type: CALL_AUTH
  };
};

export const callAuthSuccess = (): AuthAction => {
  return {
    type: CALL_AUTH_SUCCESS
  };
};

export const callAuthFailed = (err: string | null): AuthAction => {
  return {
    type: CALL_AUTH_FAILED,
    payload: err
  };
};

export const setForgotPasswordToken = (token: Token): AuthAction => {
  return {
    type: FORGOT_PASSWORD,
    payload: token
  };
};

export const setAuthUser = (authUser: AuthUser | null): AuthAction => {
  return {
    type: SET_AUTH_USER,
    payload: authUser
  };
};

export const logOutUser = (): AuthAction => {
  return {
    type: LOG_OUT_USER
  };
};

// ASYNC Actions
/**
 *
 * @param email the user-provided email
 * @param password the user-provided password
 * @description logs in a user
 */
export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    dispatch(callAuth());
    try {
      const response = await loginAxiosInstance.request({
        url: apiEndpoints.login,
        method: 'POST',
        data: { email, password }
      });
      const {
        dateCreated,
        email: userEmail,
        firstname,
        lastname,
        accessToken
      } = response.data;

      const authUser = {
        dateCreated,
        email: userEmail,
        firstname,
        lastname,
        token: accessToken
      } as AuthUser;
      dispatch(setAuthUser(authUser));
      dispatch(callAuthSuccess());
    } catch (err: any) {
      const message =
        isArray(err) && err[0].message === 'Invalid value'
          ? 'Incorrect username / password.'
          : err.message;
      dispatch(callAuthFailed(message));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param email the authenticated user email
 * @param password the authenticated user desired new password
 * @param repeatPassword the authenticated user desired password (confirm password)
 * @param oldPassword the authenticated user old password
 * @description changes the authenticated user's password (done in Profile Settings page)
 */
export const changePassword =
  (
    email: string,
    password: string,
    repeatPassword: string,
    oldPassword: string,
    callback?: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(callAuth());
    try {
      const response = await forgotPasswordAxiosInstance.request({
        url: apiEndpoints.changePassword,
        method: 'PUT',
        data: { email, password, repeatPassword, oldPassword }
      });
      const forgotPasswordToken = response.data;
      dispatch(setForgotPasswordToken(forgotPasswordToken));
      dispatch(callAuthSuccess());

      dispatch(showSuccessSnackbar('Password successfully changed!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(callAuthFailed(err.message));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param email the email of the user who desires to request a password reset link
 * @description requests the server to send a password reset link to the provided user email. Done in Forgot Password page
 */
export const sendPasswordResetLink =
  (email: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    const url = `${apiEndpoints.changePassword}/${email}`;
    dispatch(callAuth());
    try {
      const response = await forgotPasswordAxiosInstance.request({ url });
      const forgotPasswordToken = response.data;
      dispatch(setForgotPasswordToken(forgotPasswordToken));
      dispatch(callAuthSuccess());

      if (callback) callback();
    } catch (err: any) {
      dispatch(callAuthFailed(err.message));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param token the token from the password reset link url
 * @param password the user's new password
 * @param repeatPassword the user's password confirmation
 * @description requests the server to change a user's password
 */
export const resetPassword =
  (
    token: string,
    password: string,
    repeatPassword: string,
    callback?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const url = `${apiEndpoints.changePassword}/${token}`;
    dispatch(callAuth());
    try {
      const data = { password, repeatPassword };

      const response = await forgotPasswordAxiosInstance.request({
        url,
        data,
        method: 'POST'
      });
      console.log(response.data);
      dispatch(callAuthSuccess());
      if (callback) callback();
    } catch (err: any) {
      dispatch(callAuthFailed(err.message));
      errorHandler(err, dispatch);
    }
  };
