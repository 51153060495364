import moment from 'moment';
import { axiosInstance } from '../../../utils/api';
import { apiEndpoints } from '../../../utils/constants';
import errorHandler from '../../../utils/errorHandler';
import { downloadResponseData } from '../../../utils/helpers';
import { AppThunk } from '../../index';
import { showSuccessSnackbar } from '../../system/actions';
import { Status } from '../../system/types';
import {
  ADD_CONTACT,
  CLEAR_SELECTED_CONTACTS,
  ContactBase,
  ContactListAction,
  DELETE_CONTACT,
  IContact,
  SET_CONTACTS_DATA,
  SET_CONTACTS_STATUS,
  SET_SELECTED_CONTACTS,
  UPDATE_CONTACT,
  UPDATE_CONTACT_STATUS
} from './types';

// SYNC ACTIONS
export const setContactListStatus = (status: Status): ContactListAction => ({
  type: SET_CONTACTS_STATUS,
  payload: status
});

export const setContactListData = (data: IContact[]): ContactListAction => ({
  type: SET_CONTACTS_DATA,
  payload: data
});

export const setSelectedContacts = (contactId: string): ContactListAction => ({
  type: SET_SELECTED_CONTACTS,
  payload: contactId
});

export const addContactSync = (newContact: IContact): ContactListAction => ({
  type: ADD_CONTACT,
  payload: { newContact }
});

export const updateContactSync = (
  id: string,
  updatedContact: ContactBase
): ContactListAction => ({
  type: UPDATE_CONTACT,
  payload: { id, updatedContact }
});

export const updateContactStatusSync = (id: string): ContactListAction => ({
  type: UPDATE_CONTACT_STATUS,
  payload: { id }
});

export const deleteContactSync = (id: string): ContactListAction => ({
  type: DELETE_CONTACT,
  payload: { id }
});

export const clearSelectedContacts = (): ContactListAction => ({
  type: CLEAR_SELECTED_CONTACTS
});

// ASYNC ACTIONS

/**
 *
 * @description Fetches the Contact list from the database
 */
export const fetchContacts = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setContactListStatus('loading'));

    const response = await axiosInstance.request({
      url: apiEndpoints.contacts
    });

    dispatch(setContactListData(response.data));
  } catch (err: any) {
    dispatch(setContactListStatus('error'));
    errorHandler(err, dispatch);
  }
};

/**
 *
 * @param contact the new Contact
 * @param callback the function to call after successful create
 * @description Creates a new Contact through a POST request to the server
 */
export const createContact =
  (contact: ContactBase, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setContactListStatus('loading'));

      const response = await axiosInstance.request({
        method: 'POST',
        data: contact,
        url: apiEndpoints.contacts
      });

      dispatch(addContactSync(response.data));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setContactListStatus('error'));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param contact the updated Contact
 * @param callback the function to call after successful update
 * @description Updates an existing Contact through a PUT request to the server
 */
export const updateContact =
  (id: string, contact: ContactBase, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setContactListStatus('loading'));

      await axiosInstance.request({
        method: 'PUT',
        data: contact,
        url: `${apiEndpoints.contacts}/${id}`
      });

      dispatch(updateContactSync(id, contact));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setContactListStatus('error'));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param contactIds the ids of the contacts to be updated from `registered` to `contacted`
 * @param callback the function to call after successful update
 * @description updates the status of contacts from `registered` to `contacted`
 */
export const updateContactStatus =
  (contactIds: string[], callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setContactListStatus('loading'));
      let promises = [];
      for (const id of contactIds) {
        promises.push(
          axiosInstance.request({
            method: 'PUT',
            data: { status: 'Contacted' },
            url: `${apiEndpoints.contacts}/${id}`
          })
        );
      }

      await Promise.all(promises);

      for (const id of contactIds) {
        dispatch(updateContactStatusSync(id));
      }

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setContactListStatus('error'));
      errorHandler(err, dispatch);
    } finally {
      dispatch(setContactListStatus('idle'));
      dispatch(clearSelectedContacts());
    }
  };

/**
 *
 * @param id the id of the contact to be deleted
 * @param callback the callback to call after successful deletion
 * @description deletes a contact
 */
export const deleteContact =
  (id: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setContactListStatus('loading'));

      await axiosInstance.request({
        method: 'DELETE',
        url: `${apiEndpoints.contacts}/${id}`
      });

      dispatch(deleteContactSync(id));

      dispatch(showSuccessSnackbar('Successfully deleted!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setContactListStatus('error'));
      errorHandler(err.message, dispatch);
    }
  };

export const exportContacts = (): AppThunk => async (dispatch) => {
  try {
    const response = await axiosInstance.request({
      url: `${apiEndpoints.contacts}/export`,
      responseType: 'blob'
    });

    const filename = `Contact_List_${moment().format('MM-DD-YY')}.xlsx`;

    if (response.status === 200 || response.status === 204) {
      downloadResponseData(response.data, filename);
    }
  } catch (err: any) {
    dispatch(setContactListStatus('error'));
    errorHandler(err.message, dispatch);
  }
};
