import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddOutlined';

type AddButtonProps = {
  onClick?: () => void;
};

const AddButton = ({
  onClick,
  ...props
}: AddButtonProps & ButtonProps): JSX.Element => {
  return (
    <Button
      {...props}
      startIcon={<AddIcon fontSize="small" />}
      onClick={onClick}
      disableElevation
      sx={{
        color: 'white',
        height: '100%',
        ...props.sx
      }}>
      Add
    </Button>
  );
};

export default AddButton;
