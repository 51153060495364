import { AnyAction } from 'redux';
import { AuthUser } from '../auth/types';

export interface Session {
  iat: number;
  exp: number;
  token: string | AuthUser;
}

export interface Snackbar {
  open: boolean;
  type: 'success' | 'error' | 'warning';
  message?: string | null;
}

export interface SystemState {
  session: Session | null | undefined;
  snackbar: Snackbar | null | undefined;
  sidebarHidden: boolean;
  loading: boolean;
}

export type Status = 'idle' | 'loading' | 'success' | 'error';
export type AppError = Error | string | undefined;

export const SET_SESSION = 'set_session';
export const SET_SNACKBAR = 'set_snackbar';
export const SET_SYSTEM_LOADING = 'set_system_loading';
export const TOGGLE_SIDEBAR = 'set_system_sidebar';

export interface SetSessionAction {
  type: typeof SET_SESSION;
  payload: Session;
}

export interface SetSnackbarAction {
  type: typeof SET_SNACKBAR;
  payload: Snackbar;
}

export interface SetSystemLoadingAction {
  type: typeof SET_SYSTEM_LOADING;
  payload: boolean;
}

export interface ToggleSidebarAction {
  type: typeof TOGGLE_SIDEBAR;
  payload: null;
}

export type SystemAction =
  | SetSessionAction
  | SetSnackbarAction
  | SetSystemLoadingAction
  | ToggleSidebarAction
  | AnyAction;
