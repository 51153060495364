import { AppState } from '../../index';
import { orderBy } from 'lodash';

export const getLibSurveyState = (state: AppState) => state.surveyLibrary;

export const getLibSurveyById = (id: string) => (state: AppState) =>
  state.surveyLibrary.data.find((i) => i.id === id);

export const getTransformedLibSurveyList = (state: AppState) =>
  orderBy(state.surveyLibrary.data, ['dateCreated'], ['desc']).map(
    (i, idx) => ({
      index: idx + 1,
      ...i
    })
  );
