import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import AMITable from '../../../components/common/AMITable';
import AMITableHeading from '../../../components/common/AMITableHeading';
import { TableColumn, TableOwnProps } from '../../../utils/types';
import contactListColumns from './contactListColumns';

import {
  getContactState,
  getTransformedContactList
} from '../../../store/contact-mngt/contact-list/selectors';
import AMIEnhancedTableBody from '../../../components/common/AMIEnhancedTableBody';
import ContactNotes from './ContactNotes';
import AMIEnhancedTableRow from '../../../components/common/AMIEnhancedTableRow';
import useTableUtils from '../../../hooks/useTableUtils';
import { PaperTableContainer } from '../../../components/common/AppContainersLib';
import { setSelectedContacts } from '../../../store/contact-mngt/contact-list/actions';

const ContactListTable = ({
  searchKeyword
}: Omit<TableOwnProps, 'rowsPerPage'>) => {
  const {
    page,
    paginate,
    sort,
    sortColumn,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    filterBySearch,
    rowsPerPage,
    setPage
  } = useTableUtils({ initialSortBy: 'index' });

  const { selectedContacts } = useSelector(getContactState);
  const data = useSelector(getTransformedContactList);
  const dispatch = useDispatch();

  const columns = React.useMemo<TableColumn[]>(() => contactListColumns, []);

  useEffect(() => {
    if (searchKeyword && page !== 0) setPage(0);
  }, [page, searchKeyword, setPage]);

  const filteredData = searchKeyword
    ? filterBySearch(data, searchKeyword)
    : data;

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);
  const paginatedData: typeof data = paginate(sortedData, page, rowsPerPage);

  const handleItemCheckChange = (itemId: string) => {
    dispatch(setSelectedContacts(itemId));
  };

  return (
    <PaperTableContainer id="contact-list-table" elevation={0}>
      <AMITable>
        <AMITableHeading
          columns={columns}
          sortColumn={sortColumn}
          onSort={sortHandler}
        />
        <AMIEnhancedTableBody
          dataLength={paginatedData.length}
          columns={columns}>
          {paginatedData &&
            paginatedData.map((item, index) => (
              <AMIEnhancedTableRow
                key={item.id}
                item={item}
                index={index}
                columns={columns}
                onItemCheckChange={handleItemCheckChange}
                isSelected={selectedContacts.includes(item.id)}
                isCheckboxDisabled={item.status === 'Contacted'}
                collapseContent={<ContactNotes contact={item} />}
              />
            ))}
        </AMIEnhancedTableBody>
      </AMITable>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PaperTableContainer>
  );
};

export default ContactListTable;
