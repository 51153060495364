import Typography from '@mui/material/Typography';
import { appColors } from '../../theme';

interface IPageTitleProps {
  title: String;
}

const PageTitle = (props: IPageTitleProps) => {
  return (
    <Typography
      pt={3}
      pb={1}
      sx={{ textTransform: 'uppercase' }}
      color={appColors.titleGray}
      fontSize={19}
      fontWeight={500}>
      {props.title}
    </Typography>
  );
};

export default PageTitle;
