import { AppThunk } from '../..';
import { axiosInstance } from '../../../utils/api';
import { apiEndpoints } from '../../../utils/constants';
import errorHandler from '../../../utils/errorHandler';
import { showSuccessSnackbar } from '../../system/actions';
import { Status } from '../../system/types';
import {
  ADD_CONTACT_NOTE,
  ContactNote,
  ContactNotesAction,
  SET_CONTACT_NOTES_DATA,
  SET_CONTACT_NOTES_STATUS
} from './types';

// Action Creators
export const setContactNotesData = (
  contactNotes: ContactNote[]
): ContactNotesAction => ({
  type: SET_CONTACT_NOTES_DATA,
  payload: { data: contactNotes }
});

const setContactNotesStatus = (status: Status): ContactNotesAction => ({
  type: SET_CONTACT_NOTES_STATUS,
  payload: { status }
});

const addContactNote = (newContactNote: ContactNote) => ({
  type: ADD_CONTACT_NOTE,
  payload: { newContactNote }
});

// Async Actions
export const fetchContactNotes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setContactNotesStatus('loading'));

    const response = await axiosInstance.request({
      url: apiEndpoints.contactNotes
    });

    dispatch(setContactNotesData(response.data));
  } catch (err: any) {
    dispatch(setContactNotesStatus('error'));
    errorHandler(err, dispatch);
  }
};

export const createContactNote =
  (
    contactNote: Pick<ContactNote, 'contactId' | 'note'>,
    callback?: () => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setContactNotesStatus('loading'));

      const response = await axiosInstance.request({
        url: apiEndpoints.contactNotes,
        method: 'POST',
        data: contactNote
      });

      dispatch(addContactNote(response.data));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();

      dispatch(setContactNotesStatus('idle'));
    } catch (err: any) {
      dispatch(setContactNotesStatus('error'));
      errorHandler(err, dispatch);
    }
  };
