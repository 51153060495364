import { AppState } from '../index';
import { FeedbackAnswerType, FeedbackDataValueType } from './types';

export const getCalcFeedbackData = (state: AppState) => {
  const fd = state.calcFeedback.feedbackData;
  const fQuestions = state.feedbackLibrary.data;

  return fd.reduce<Record<string, FeedbackDataValueType>>((acc, curr) => {
    let answer: Record<FeedbackAnswerType, number> = {
      'Very Satisfied': 0,
      Satisfied: 0,
      Neutral: 0,
      Unsatisfied: 0,
      'Very Unsatisfied': 0
    };

    const questionExists = fQuestions.some((q) => q.id === curr.id);

    if (!questionExists) return acc;

    answer = acc[curr.id] ? acc[curr.id].answer : answer;

    acc = {
      ...acc,
      [curr.id]: {
        question: fQuestions.find((q) => q.id === curr.id)?.question as string,
        answer: {
          ...answer,
          [curr.answer]: answer[curr.answer] + 1
        }
      }
    };
    return acc;
  }, {});
};
