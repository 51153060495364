export type FeedbackAnswerType =
  | 'Very Satisfied'
  | 'Satisfied'
  | 'Neutral'
  | 'Unsatisfied'
  | 'Very Unsatisfied';

export interface FeedbackAnswer {
  id: string;
  question: string;
  answer: FeedbackAnswerType;
}

export interface SurveyAnswer {
  id: string;
  question: string;
  answer: string;
}

export type FeedbackDataValueType = {
  question: string;
  answer: Record<FeedbackAnswerType, number>;
};

export interface CalcFeedbackState {
  feedbackData: FeedbackAnswer[];
  surveyData: SurveyAnswer[];
}

export const SET_CALC_FEEDBACK_DATA = 'calcFeedback/data/set';

interface SetCalcFeedbackDataAction {
  type: typeof SET_CALC_FEEDBACK_DATA;
  payload: { feedbackData: FeedbackAnswer[] };
}

export type CalcFeebackAction = SetCalcFeedbackDataAction;
