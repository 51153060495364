import { AppState } from '../../index';
import { orderBy } from 'lodash';

export const getFAQState = (state: AppState) => state.faq;

export const getFAQById = (id: string) => (state: AppState) =>
  state.faq.data.find((f) => f.id === id);

export const getTransformedFAQList = (state: AppState) =>
  orderBy(state.faq.data, ['dateCreated'], ['desc']).map((i, idx) => ({
    index: idx + 1,
    ...i
  }));
