import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AMIDialog from '../../components/common/AMIDialog';
import { deleteUser } from '../../store/users/actions';
import { selectUserById } from '../../store/users/selectors';
import { IDParams } from '../../utils/types';

const UserDeleteModal = () => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const closeDialog = () => history.push('/users');

  const handleDelete = () => dispatch(deleteUser(id, closeDialog));
  const userName = useSelector(selectUserById(id));
  const deleteMessaage = `Are you sure you want to delete ${userName?.firstname} ${userName?.lastname}?`

  return (
    <AMIDialog
      variant="delete"
      title="Delete"
      actionButtonText="Confirm"
      onClose={closeDialog}
      onActionBtnClick={handleDelete}>
      <AMIDialog.DeleteMessage message= {deleteMessaage} />
    </AMIDialog>
  );
};

export default UserDeleteModal;
