import {
  ADD_CONTACT,
  CLEAR_SELECTED_CONTACTS,
  ContactListAction,
  ContactState,
  DELETE_CONTACT,
  SET_CONTACTS_DATA,
  SET_CONTACTS_STATUS,
  SET_SELECTED_CONTACTS,
  UPDATE_CONTACT,
  UPDATE_CONTACT_STATUS
} from './types';

const initialState: ContactState = {
  data: [],
  status: 'idle',
  error: undefined,
  selectedContacts: []
};

export default function reducer(
  state = initialState,
  action: ContactListAction
): ContactState {
  switch (action.type) {
    case SET_CONTACTS_STATUS:
      return { ...state, status: action.payload };
    case SET_CONTACTS_DATA:
      return { ...state, data: action.payload, status: 'idle' };
    case SET_SELECTED_CONTACTS: {
      let contacts = [...state.selectedContacts];
      if (contacts.includes(action.payload)) {
        contacts = contacts.filter((c) => c !== action.payload);
      } else {
        contacts = [...contacts, action.payload];
      }
      return { ...state, selectedContacts: contacts };
    }
    case CLEAR_SELECTED_CONTACTS:
      return {
        ...state,
        selectedContacts: []
      };
    case ADD_CONTACT:
      return {
        ...state,
        data: [action.payload.newContact, ...state.data],
        status: 'idle'
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        data: state.data.map((i) =>
          i.id === action.payload.id
            ? { ...i, ...action.payload.updatedContact }
            : i
        ),
        status: 'idle'
      };
    case UPDATE_CONTACT_STATUS:
      return {
        ...state,
        data: state.data.map((i) =>
          i.id === action.payload.id ? { ...i, status: 'Contacted' } : i
        )
      };
    case DELETE_CONTACT:
      return {
        ...state,
        data: state.data.filter((i) => i.id !== action.payload.id),
        status: 'idle'
      };

    default:
      return state;
  }
}
