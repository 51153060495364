import React, { useState } from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PageTitle from '../../../components/common/PageTitle';
import SearchField from '../../../components/common/SearchField';

import AddButton from '../../../components/common/AddButton';
import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../../components/common/AppContainersLib';
import FAQTable from './FAQTable';
import FAQDeleteModal from './FAQDeleteModal';
import FAQForm from './FAQForm';

const FAQListView = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleAddClick = () => history.push('/library/faq/create');

  return (
    <React.Fragment>
      <Route path={`${path}/create`}>
        <FAQForm />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <FAQForm isEdit />
      </Route>
      <Route path={`${path}/delete/:id`}>
        <FAQDeleteModal />
      </Route>
      <PageWithTableContainer>
        <PageTitle title="FAQ" />
        <ListViewContainer>
          <ControlsContainer>
            <Box width={200}>
              <SearchField
                searchKeyword={searchKeyword}
                onSearchKeywordChange={handleSearch}
              />
            </Box>
            <Stack ml="auto" direction="row" spacing={1}>
              <AddButton onClick={handleAddClick} />
            </Stack>
          </ControlsContainer>
          <FAQTable searchKeyword={searchKeyword} />
        </ListViewContainer>
      </PageWithTableContainer>
    </React.Fragment>
  );
};

export default FAQListView;
