import React, { useState } from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PageTitle from '../../components/common/PageTitle';
import SearchField from '../../components/common/SearchField';

import AddButton from '../../components/common/AddButton';
import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../components/common/AppContainersLib';
import UserTable from './UserTable';
import UserDeleteModal from './UserDeleteModal';
import UserForm from './UserForm';

const UserListView = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleAddClick = () => history.push('/users/create');

  return (
    <React.Fragment>
      <Route path={`${path}/create`}>
        <UserForm />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <UserForm isEdit />
      </Route>
      <Route path={`${path}/delete/:id`}>
        <UserDeleteModal />
      </Route>
      <PageWithTableContainer>
        <PageTitle title="USER MANAGEMENT" />
        <ListViewContainer>
          <ControlsContainer>
            <Box width={200}>
              <SearchField
                searchKeyword={searchKeyword}
                onSearchKeywordChange={handleSearch}
              />
            </Box>
            <Stack ml="auto" direction="row" spacing={1}>
              <AddButton onClick={handleAddClick} />
            </Stack>
          </ControlsContainer>
          <UserTable searchKeyword={searchKeyword} rowsPerPage={10} />
        </ListViewContainer>
      </PageWithTableContainer>
    </React.Fragment>
  );
};

export default UserListView;
