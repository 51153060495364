import { ChangeEvent, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import AddIcon from '@mui/icons-material/AddCircle';
import { RiDeleteBin6Line as DeleteIcon } from 'react-icons/ri';

import { appColors } from '../../../theme';

interface DropdownOptionsFieldProps {
  optionList?: string[];
  onAddOption: (option: string) => void;
  onDeleteOption: (option: string) => void;
}

const DropdownOptionsField = (props: DropdownOptionsFieldProps) => {
  const [fieldShown, setFieldShown] = useState(false);
  const [option, setOption] = useState('');
  const [error, setError] = useState<string | null>(null);

  const { optionList, onAddOption, onDeleteOption } = props;

  const handleAdd = () => {
    if (!option || option === '')
      return setError('An option cannot be an empty value.');

    if (optionList?.includes(option.trim()))
      return setError('The provided option is already on the list.');

    onAddOption(option.trim());

    setFieldShown(false);
    setOption('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setOption(e.target.value);
  };

  return (
    <Stack mb={1}>
      <Typography component="label" fontSize={14} htmlFor="email" mb="3px">
        Drop down selection
      </Typography>
      <Box
        height={185}
        overflow="auto"
        border={1}
        p="8.5px 14px"
        borderRadius="4px"
        borderColor={appColors.inputBorder}>
        <Stack
          alignItems="flex-start"
          pb={1}
          borderBottom={fieldShown ? 0 : 1}
          borderColor={appColors.inputBorder}>
          {optionList &&
            optionList.map((item, idx) => (
              <Stack
                key={item + idx}
                width="100%"
                flexDirection="row"
                alignItems="center"
                pb={1}
                mb={1}
                borderBottom={1}
                borderColor={appColors.inputBorder}>
                <Typography fontSize={14}>
                  {idx + 1}. &nbsp; {item}
                </Typography>
                <IconButton
                  sx={{ ml: 'auto' }}
                  onClick={() => onDeleteOption(item)}
                  aria-label="Delete option">
                  <DeleteIcon fontSize={20} />
                </IconButton>
              </Stack>
            ))}
          {!fieldShown ? (
            <Button
              onClick={() => setFieldShown(true)}
              startIcon={<AddIcon color="primary" />}
              variant="text"
              color="secondary">
              Add selection
            </Button>
          ) : (
            <TextField
              value={option}
              onChange={handleChange}
              fullWidth
              error={error ? true : false}
              helperText={error}
              placeholder="Enter an option here"
              size="small"
              InputProps={{
                sx: { fontSize: '14px', pr: '3px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleAdd}
                      sx={{ width: 'max-content', px: 0 }}
                      size="small">
                      Add
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default DropdownOptionsField;
