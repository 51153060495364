import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import AppField from '../../../components/common/AppFormsLib';

import { IContact } from '../../../store/contact-mngt/contact-list/types';
import ContactNotesTable from './ContactNotesTable';
import {
  getContactNoteByContactId,
  getContactNotesState
} from '../../../store/contact-mngt/contact-note/selectors';
import { createContactNote } from '../../../store/contact-mngt/contact-note/actions';

interface ContactNotesProps {
  contact: IContact;
}

const ContactNotes = (props: ContactNotesProps) => {
  const [note, setNote] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { status } = useSelector(getContactNotesState);

  const { contact } = props;

  const isLoading = status === 'loading';

  const notes = useSelector(getContactNoteByContactId(contact.id));

  const clearField = () => setNote('');

  const handleSubmit = () => {
    if (notes.some((n) => n.note === note.trim()))
      return setError('The provided value already exists.');

    const noteData = { contactId: contact.id, note: note.trim() };
    dispatch(createContactNote(noteData, clearField));
  };

  return (
    <Stack
      pt={2}
      spacing={1}
      borderTop={1}
      borderColor={(theme) => theme.palette.grey[200]}>
      <Grid mb={3} component="form" container width="45%" spacing={2}>
        <Grid item xs={10}>
          <AppField
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              minHeight: '100%',
              height: '70px'
            }}
            error={error ? true : false}
            helperText={error}
            rows={3}
            multiline
            placeholder="Add notes here..."
            value={note}
            onChange={(e) => {
              setError('');
              setNote(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {note && (
            <Stack ml="auto" spacing={1}>
              <Button
                onClick={handleSubmit}
                sx={{ minWidth: '80px' }}
                size="small"
                disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add'}
              </Button>
              <Button
                sx={{ minWidth: '80px' }}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={clearField}>
                Clear
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
      <ContactNotesTable notesData={notes} />
    </Stack>
  );
};

export default ContactNotes;
