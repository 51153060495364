import _ from 'lodash';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
// for table pagination
export const paginate = (
  items: any[],
  currentPage: number,
  pageSize: number
) => {
  const startIndex = currentPage * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
};

// for table sorting
export const sort = (items: any[], path: string, order: 'asc' | 'desc') =>
  _.orderBy(items, [path], [order]);
//for table custom sorting
export const customSort = (items: any[], path: any, order: 'asc' | 'desc') => {
  const handleField = (items: any) => {
    if (items[path]) {
      return items[path].toString().toLowerCase();
    }

    return items[path];
  };

  return orderBy(items, handleField, order);
};
// for formatting datePosted (and dateExpired)
export const formatDatePosted = (datePosted: Date | null | string) => {
  return moment(datePosted).format('MMMM D, YYYY');
};

// for formatting dateCreated of contact notes
export const formatContactNotesDate = (dateCreated: Date | null | string) => {
  return moment(dateCreated).format('M/D/YYYY h:mm:ss A');
};

// for parsing material datepicker date
export const parseDate = (dateValue: Date | null | string) => {
  if (dateValue === null) return '';
  return moment(dateValue).format('YYYY-MM-DD HH:mm:ss.SSSZ');
};

export const downloadResponseData = (data: any, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};
