import {
  ADD_LIB_FEEDBACK,
  DELETE_LIB_FEEDBACK,
  SET_LIB_FEEDBACK,
  UPDATE_LIB_FEEDBACK,
  CALL_LIB_FEEDBACK_API,
  CALL_LIB_FEEDBACK_API_SUCCESS,
  CALL_LIB_FEEDBACK_API_FAILED,
  LibFeedbackAction,
  LibFeedbackState
} from './types';

const INITIAL_STATE: LibFeedbackState = {
  data: [],
  status: 'idle',
  error: null
};

export default function usersReducers(
  state = INITIAL_STATE,
  action: LibFeedbackAction
): LibFeedbackState {
  switch (action.type) {
    case CALL_LIB_FEEDBACK_API:
      return { ...state, status: 'loading' };
    case CALL_LIB_FEEDBACK_API_SUCCESS:
      return { ...state, status: 'success' };
    case CALL_LIB_FEEDBACK_API_FAILED:
      return { ...state, status: 'failed', error: action.payload };
    case SET_LIB_FEEDBACK:
      return { ...state, data: action.payload };
    case ADD_LIB_FEEDBACK:
      return { ...state, data: [{ ...action.payload }, ...state.data] };
    case UPDATE_LIB_FEEDBACK:
      return {
        ...state,
        data: state.data.map((u) =>
          u.id === action.payload.id
            ? { id: u.id, ...action.payload.updatedLibFeedback }
            : u
        )
      };
    case DELETE_LIB_FEEDBACK:
      return {
        ...state,
        data: state.data.filter((u) => u.id !== action.payload.id)
      };
    default:
      return state;
  }
}
