import React, { useState } from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import PageTitle from '../../../components/common/PageTitle';
import SearchField from '../../../components/common/SearchField';
import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../../components/common/AppContainersLib';
import FeedbackTable from './FeedbackTable';
import FeedbackDeleteModal from './FeedbackDeleteModal';
import { Button, Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackState } from '../../../store/contact-mngt/feedback-list/selectors';
import { exportFeedback } from '../../../store/contact-mngt/feedback-list/actions';

const FeedbackListView = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const { path } = useRouteMatch();

  const dispatch = useDispatch();
  const { data } = useSelector(getFeedbackState);
  const handleExport = () => dispatch(exportFeedback());
  const hasData = data.length > 0;
  
  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  return (
    <React.Fragment>
      <Route path={`${path}/delete/:id`}>
        <FeedbackDeleteModal />
      </Route>
      <Route path={`${path}`}>
        <PageWithTableContainer>
          <PageTitle title="FEEDBACK LIST" />
          <ListViewContainer>
            <ControlsContainer>
              <Box width={200}>
                <SearchField
                  searchKeyword={searchKeyword}
                  onSearchKeywordChange={handleSearch}
                />
              </Box>

              <Stack ml="auto" direction="row" spacing={1}>
                {hasData && (
                  <>
                    <Button
                      aria-label="export contacts"
                      onClick={handleExport}
                      startIcon={<DownloadIcon fontSize="small" />}>
                      Export
                    </Button>
                  </>
                )}
              </Stack>
            </ControlsContainer>
            <FeedbackTable searchKeyword={searchKeyword} rowsPerPage={10} />
          </ListViewContainer>
        </PageWithTableContainer>
      </Route>
    </React.Fragment>
  );
};

export default FeedbackListView;
