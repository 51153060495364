import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import FAQPage from './FAQ';
import LibraryFeedbackPage from './Feedback';
import LibrarySurveyPage from './Survey';

const LibraryPage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/faq`}>
        <FAQPage />
      </Route>
      <Route path={`${path}/survey`}>
        <LibrarySurveyPage />
      </Route>
      <Route path={`${path}/feedback`}>
        <LibraryFeedbackPage />
      </Route>
    </Switch>
  );
};

export default LibraryPage;
