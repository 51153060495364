import { AppThunk } from '../../index';
import { axiosInstance } from '../../../utils/api';
import { apiEndpoints } from '../../../utils/constants';
import errorHandler from '../../../utils/errorHandler';
import { showSuccessSnackbar } from '../../system/actions';
import {
  ADD_LIB_FEEDBACK,
  CALL_LIB_FEEDBACK_API,
  CALL_LIB_FEEDBACK_API_FAILED,
  CALL_LIB_FEEDBACK_API_SUCCESS,
  DELETE_LIB_FEEDBACK,
  SET_LIB_FEEDBACK,
  UPDATE_LIB_FEEDBACK,
  Feedback,
  LibFeedbackAction
} from './types';

export const callLibFeedbacksApi = (): LibFeedbackAction => {
  return {
    type: CALL_LIB_FEEDBACK_API
  };
};

export const callLibFeedbacksApiSuccess = (): LibFeedbackAction => {
  return {
    type: CALL_LIB_FEEDBACK_API_SUCCESS
  };
};

export const callLibFeedbacksApiFailed = (
  err: string | null
): LibFeedbackAction => {
  return {
    type: CALL_LIB_FEEDBACK_API_FAILED,
    payload: err
  };
};

export function setLibFeedbacks(feedbacks: Feedback[]): LibFeedbackAction {
  return {
    type: SET_LIB_FEEDBACK,
    payload: feedbacks
  };
}

export function addLibFeedback(feedback: Feedback): LibFeedbackAction {
  return {
    type: ADD_LIB_FEEDBACK,
    payload: feedback
  };
}

export function updateLibFeedbackSync(
  id: string,
  updatedLibFeedback: Feedback
): LibFeedbackAction {
  return {
    type: UPDATE_LIB_FEEDBACK,
    payload: {
      id,
      updatedLibFeedback
    }
  };
}

export function deleteLibFeedbackSync(id: string): LibFeedbackAction {
  return {
    type: DELETE_LIB_FEEDBACK,
    payload: {
      id
    }
  };
}

// ASYNC ACTIONS

/**
 *
 * @description Fetches all Feedback
 */
export const getLibFeedbacks = (): AppThunk => async (dispatch) => {
  dispatch(callLibFeedbacksApi());
  try {
    const response = await axiosInstance.request({
      url: apiEndpoints.feedbacks
    });
    dispatch(setLibFeedbacks(response.data));
    dispatch(callLibFeedbacksApiSuccess());
  } catch (err: any) {
    dispatch(callLibFeedbacksApiFailed(err.message));
    errorHandler(err, dispatch);
  }
};

/**
 *
 * @param data the newly created LibFeedback
 * @description creates a new LibFeedback
 */
export const createLibFeedback =
  (data: Feedback, callback?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(callLibFeedbacksApi());
    try {
      const response = await axiosInstance.request({
        url: apiEndpoints.feedbacks,
        data,
        method: 'POST'
      });

      dispatch(addLibFeedback(response.data));
      dispatch(callLibFeedbacksApiSuccess());
      dispatch(showSuccessSnackbar('Successfully saved!'));
      if (callback) callback();
    } catch (err: any) {
      dispatch(callLibFeedbacksApiFailed(err.message));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param id the id of the feedback to be updated
 * @param data the updated feedback
 * @description updates an existing feedback
 */

export const updateLibFeedback =
  (id: string, feedback: Feedback, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      await axiosInstance.request({
        method: 'PUT',
        data: feedback,
        url: `${apiEndpoints.feedbacks}/${id}`
      });

      dispatch(updateLibFeedbackSync(id, feedback));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(callLibFeedbacksApiFailed(err.message));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param id the id of the feedback to be deleted
 * @description deletes an existing feedback
 */
export const deleteLibFeedback =
  (id: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(callLibFeedbacksApi());
    const _url = `${apiEndpoints.feedbacks}/${id}`;
    try {
      const response = await axiosInstance.request({
        url: _url,
        method: 'DELETE'
      });
      const deletedLibFeedbackId = response.data;
      dispatch(deleteLibFeedbackSync(deletedLibFeedbackId));
      dispatch(callLibFeedbacksApiSuccess());
      dispatch(showSuccessSnackbar('The question was deleted successfully!'));
      if (callback) callback();
    } catch (err: any) {
      dispatch(callLibFeedbacksApiFailed(err.message));
      errorHandler(err, dispatch);
    }
  };
