import { AppError, Status } from '../../system/types';

// STATE TYPES
export interface ContactNote {
  id: string;
  contactId: string;
  note: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface ContactNoteState {
  data: ContactNote[];
  status: Status;
  error: AppError;
}

// ACTION TYPES
export const SET_CONTACT_NOTES_DATA = 'contactNotes/data/set';
export const SET_CONTACT_NOTES_STATUS = 'contactNotes/status/set';
export const ADD_CONTACT_NOTE = 'contactNotes/data/add';

interface SetContactNotesDataAction {
  type: typeof SET_CONTACT_NOTES_DATA;
  payload: { data: ContactNote[] };
}

interface SetContactNotesStatusAction {
  type: typeof SET_CONTACT_NOTES_STATUS;
  payload: { status: Status };
}

interface AddContactNoteAction {
  type: typeof ADD_CONTACT_NOTE;
  payload: { newContactNote: ContactNote };
}

export type ContactNotesAction =
  | SetContactNotesDataAction
  | SetContactNotesStatusAction
  | AddContactNoteAction;
