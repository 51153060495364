import React from 'react';
import Button from '@mui/material/Button';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { useHistory, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AuthBackground from './AuthBackground';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/VpnKey';
import { SxProps } from '@mui/system';

import { login } from '../../store/auth/actions';
import { getIsAuthenticated } from '../../store/auth/selectors';
import AppLogo from '../../components/common/AppLogo';
import LabelWithIcon from '../../components/common/LabelWithIcon';
import PasswordField from '../../components/common/PasswordField';

const loginFormStyles: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '15px 24px',
  width: '370px',
  borderRadius: 2,
  bgcolor: 'white',
  border: `1px solid divider`
};

type Inputs = {
  password: string;
  email: string;
  rememberMe: boolean;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .required('This field is required.')
    .email('Provide a valid email.'),
  password: yup.string().required('This field is required')
});

function LogIn() {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: { password: '', email: '', rememberMe: false }
  });
  const dispatch = useDispatch();
  const hasError = (inputName: keyof Inputs) =>
    errors[inputName] ? true : false;
  const getError = (inputName: keyof Inputs) => errors[inputName]?.message;

  const onSubmit = (values: Inputs) => {
    dispatch(login(values.email, values.password));
  };

  const onError = (err: typeof errors) => {
    //console.log(err.password);
  };

  function goToForgotPassword() {
    history.push('/auth/forgot-password');
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Stack height="100%" alignItems="center">
      <AuthBackground>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, onError)}
          sx={loginFormStyles}>
          <AppLogo />
          <Stack spacing={2}>
            <Stack spacing={1}>
              <LabelWithIcon icon={<PersonIcon />} label="Email Address" />
              <TextField
                id="email"
                size="small"
                fullWidth
                placeholder="Enter email here"
                error={hasError('email')}
                helperText={getError('email')}
                {...register('email')}
              />
            </Stack>
            <Stack spacing={1}>
              <LabelWithIcon icon={<KeyIcon />} label="Password" />
              <PasswordField
                id="password"
                fullWidth
                placeholder="Enter password here"
                error={hasError('password')}
                helperText={getError('password')}
                {...register('password')}
              />
            </Stack>
          </Stack>

          <Stack spacing={3} mt={2}>
            <Controller
              name="rememberMe"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <FormControlLabel
                  sx={{ width: 'max-content' }}
                  control={<CheckBox onChange={onChange} ref={ref} />}
                  label="Remember Me"
                  labelPlacement="end"
                />
              )}
            />
            <Button type="submit" color="primary" fullWidth>
              Login
            </Button>
            <Button
              color="secondary"
              variant="text"
              fullWidth
              onClick={goToForgotPassword}>
              Forgot Password?
            </Button>
          </Stack>
        </Box>
      </AuthBackground>
    </Stack>
  );
}

export default LogIn;
