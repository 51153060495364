import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Label from '../../components/common/Label';

const ProfileField = ({ label, ...rest }: TextFieldProps): JSX.Element => {
  return (
    <Grid item xs={12} container alignItems="center">
      <Grid item xs={4}>
        <Label>{label}</Label>
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          size="small"
          type="text"
          InputProps={{ sx: { bgcolor: 'white' } }}
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

export const ProfilePasswordFieldBox = ({
  label,
  children
}: {
  label: string;
  children: ReactNode;
}) => {
  return (
    <Grid item xs={12} container alignItems="center">
      <Grid item xs={4}>
        <Label>{label}</Label>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

export default ProfileField;
