import { NavLink, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import HollowCircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import FilledCircleIcon from '@mui/icons-material/RadioButtonChecked';

interface ISidebarLinkProps {
  label: string;
  hasBullet?: boolean;
  route: string;
  onClick?: () => void;
}

const SidebarLink = (props: ISidebarLinkProps) => {
  const { pathname } = useLocation();

  const isActive = pathname === props.route;

  return (
    <ListItemButton
      onClick={props.onClick}
      component={NavLink}
      to={props.route}
      exact={props.route === '/'}
      activeStyle={{
        color: 'white'
      }}
      sx={{ pl: 3, py: 2, display: 'flex', gap: 1 }}>
      {props.hasBullet && (
        <>
          {isActive ? (
            <FilledCircleIcon color="primary" sx={{ fontSize: '12px' }} />
          ) : (
            <HollowCircleIcon sx={{ fontSize: '12px' }} />
          )}
        </>
      )}
      <Typography fontSize="13px">{props.label}</Typography>
    </ListItemButton>
  );
};

export default SidebarLink;
