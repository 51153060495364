import { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';

import { AMIDataCell } from './AMITableCell';
import AMITableRow from './AMITableRow';
import { TableColumn } from '../../utils/types';
import { appColors } from '../../theme';

interface AMIEnhancedTableRowProps {
  item: any;
  index: number;
  columns: TableColumn[];
  collapseContent?: ReactElement<any>;
  onItemCheckChange?: (itemId: string) => void;
  isSelected?: boolean;
  isCheckboxDisabled?: boolean;
}

const AMIEnhancedTableRow = (props: AMIEnhancedTableRowProps) => {
  const {
    item,
    columns,
    index,
    collapseContent,
    onItemCheckChange,
    isSelected,
    isCheckboxDisabled
  } = props;

  const [open, setOpen] = useState(false);
  // cell content renderer
  function renderCellContent(rowItem: any, rowIndex: number, column: any) {
    if (column.content) return column.content(rowItem, rowIndex);
    return rowItem[column.property];
  }

  return (
    <>
      <AMITableRow key={item.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
        {columns.map((column) => {
          if (column.dataCellType === 'checkbox')
            return (
              <AMIDataCell
                align="center"
                key={`${item.id}-${column.id}`}
                padding="checkbox"
                sx={{ border: 0 }}>
                <Checkbox
                  onChange={() => {
                    if (!onItemCheckChange) return;
                    onItemCheckChange(item.id);
                  }}
                  checked={isSelected}
                  disabled={isCheckboxDisabled}
                  color="primary"
                  inputProps={{
                    'aria-labelledby': item.id
                  }}
                />
              </AMIDataCell>
            );
          if (column.dataCellType === 'collapse')
            return (
              <AMIDataCell
                align="center"
                key={`${item.id}-${column.id}`}
                padding="checkbox"
                sx={{ border: 0 }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  color="primary"
                  sx={{
                    bgcolor: appColors.lightRed,
                    color: appColors.primary,
                    borderRadius: 1,
                    padding: 0,
                    mx: 1,
                    '&:hover': {
                      bgcolor: appColors.lightRed
                    }
                  }}
                  onClick={() => setOpen(!open)}>
                  {open ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )}
                </IconButton>
              </AMIDataCell>
            );
          return (
            <AMIDataCell
              align="center"
              key={`${item.id}-${column.id}`}
              sx={{ border: 0 }}>
              {renderCellContent(item, index, column)}
            </AMIDataCell>
          );
        })}
      </AMITableRow>
      <AMITableRow>
        <AMIDataCell sx={{ pb: 0, pt: 0, px: 1 }} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>{collapseContent}</Box>
          </Collapse>
        </AMIDataCell>
      </AMITableRow>
    </>
  );
};

export default AMIEnhancedTableRow;
