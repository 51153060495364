import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const AppFooter = (): JSX.Element => {
  return (
    <Stack
      maxHeight={48}
      direction="row"
      alignItems="center"
      component="footer"
      paddingY={2}
      paddingX={4}
      spacing={1}
      mt="auto">
      <Typography fontSize={13}>
        Copyright &copy; {new Date().getFullYear()}
      </Typography>
      <Typography color="primary" fontWeight="600" fontSize={13}>
        Ramsay.
      </Typography>
      <Typography fontSize={13}>All rights reserved.</Typography>
    </Stack>
  );
};

export default AppFooter;
