import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AMIDialog from '../../../components/common/AMIDialog';
import { deleteUser } from '../../../store/contact-mngt/survey-list/actions';
import { IDParams } from '../../../utils/types';

const UserDeleteModal = () => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const closeDialog = () => history.push('/contact/survey-list');

  const handleDelete = () => dispatch(deleteUser(id, closeDialog));

  return (
    <AMIDialog
      variant="delete"
      title="Delete"
      actionButtonText="Delete"
      onClose={closeDialog}
      onActionBtnClick={handleDelete}>
      <AMIDialog.DeleteMessage message="Do you want to delete this record?" />
    </AMIDialog>
  );
};

export default UserDeleteModal;
