import * as yup from 'yup';

export const contactSchema = yup
  .object({
    email: yup
      .string()
      .required('Email is required.')
      .email('Provide a valid email.')
      .trim(),
    name: yup.string().trim(),
    jobTitle: yup.string().trim(),
    companyName: yup.string().trim(),
    location: yup.string().trim(),
    status: yup.string().optional().oneOf(['Registered', 'Contacted'])
  })
  .required();
