import { AppError, Status } from '../../system/types';

// DATA
export type ContactStatusType = 'Registered' | 'Contacted';
export type ContactRegistrationType = 'Manual' | 'Digital';

export interface IContactNote {
  id: string;
  dateTime: Date | string;
  note: string;
}

export interface ContactBase {
  email: string;
  name: string;
  jobTitle: string;
  companyName: string;
  location: string;
  status: ContactStatusType;
  registrationType: ContactRegistrationType;
}

export interface IContact extends ContactBase {
  id: string;
  dateRegistered: Date | string;
  dateCreated: Date | string;
  dateUpdated: Date | string;
  notes: IContactNote[];
}

export interface IndexedIContact extends IContact {
  index: number;
}

export interface ContactState {
  data: IContact[];
  status: Status;
  error: AppError;
  selectedContacts: string[];
}

export const contactFormInitialValues: ContactBase = {
  email: '',
  name: '',
  jobTitle: '',
  companyName: '',
  location: '',
  status: 'Registered',
  registrationType: 'Manual'
};

// ACTIONS
export const SET_SELECTED_CONTACTS = 'contact/selected-contacts/set';
export const CLEAR_SELECTED_CONTACTS = 'contact/selected-contacts/clear';
export const SET_CONTACTS_STATUS = 'contact/status/set';
export const SET_CONTACTS_DATA = 'contacts/data/set';
export const ADD_CONTACT = 'contacts/data/add';
export const UPDATE_CONTACT = 'contacts/data/update';
export const UPDATE_CONTACT_STATUS = 'contacts/data/status/update';
export const DELETE_CONTACT = 'contacts/data/delete';

interface SetSelectedContactsAction {
  type: typeof SET_SELECTED_CONTACTS;
  payload: string;
}

interface ClearSelectedContactsAction {
  type: typeof CLEAR_SELECTED_CONTACTS;
}

interface SetContactsStatusAction {
  type: typeof SET_CONTACTS_STATUS;
  payload: Status;
}

interface SetContactsDataAction {
  type: typeof SET_CONTACTS_DATA;
  payload: IContact[];
}

interface AddContactAction {
  type: typeof ADD_CONTACT;
  payload: { newContact: IContact };
}

interface UpdateContactAction {
  type: typeof UPDATE_CONTACT;
  payload: { id: string; updatedContact: ContactBase };
}

interface UpdateContactStatusAction {
  type: typeof UPDATE_CONTACT_STATUS;
  payload: { id: string };
}

interface DeleteContactAction {
  type: typeof DELETE_CONTACT;
  payload: { id: string };
}

export type ContactListAction =
  | SetSelectedContactsAction
  | ClearSelectedContactsAction
  | SetContactsStatusAction
  | SetContactsDataAction
  | AddContactAction
  | UpdateContactAction
  | UpdateContactStatusAction
  | DeleteContactAction;
