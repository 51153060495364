import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';

import AMIDialog from '../../../components/common/AMIDialog';
import {
  AppField,
  AppSelectField
} from '../../../components/common/AppFormsLib';
import {
  FAQBase,
  faqFormInitialValues
} from '../../../store/library/FAQ/types';
import { faqSchema } from './faqSchema';
import { showErrorSnackbar } from '../../../store/system/actions';
import { IDParams } from '../../../utils/types';
import { getFAQById, getFAQState } from '../../../store/library/FAQ/selectors';
import { createFAQ, updateFAQ } from '../../../store/library/FAQ/actions';
import { useEffect } from 'react';
import { chatbotCategories } from '../../../utils/constants';

interface FAQFormProps {
  isEdit?: boolean;
}

const FAQForm = (props: FAQFormProps) => {
  const history = useHistory();
  const { id } = useParams<IDParams>();

  const faqToEdit = useSelector(getFAQById(id));
  const { status } = useSelector(getFAQState);
  const dispatch = useDispatch();
  const isLoading = status === 'loading';

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm<FAQBase>({
    resolver: yupResolver(faqSchema),
    defaultValues: faqToEdit
      ? {
          answer: faqToEdit.answer,
          question: faqToEdit.question,
          source: faqToEdit.source,
          category: faqToEdit.category
        }
      : faqFormInitialValues
  });

  useEffect(() => {
    if (id && faqToEdit) {
      setValue('question', faqToEdit.question);
      setValue('answer', faqToEdit.answer);
      setValue('category', faqToEdit.category);
    }
  }, [faqToEdit, id, setValue]);

  const closeDialog = () => history.push('/library/faq');

  const formTitle = props.isEdit ? 'Edit FAQ' : 'Add FAQ';

  const onSubmit = (faq: FAQBase) => {
    if (!props.isEdit) return dispatch(createFAQ(faq, closeDialog));

    dispatch(updateFAQ(id, faq, closeDialog));
  };

  function onError(err: typeof errors) {
    dispatch(showErrorSnackbar('Please check required field(s)'));
  }

  const hasError = (inputName: keyof FAQBase) =>
    errors[inputName] ? true : false;

  const getError = (inputName: keyof FAQBase) => errors[inputName]?.message;

  return (
    <AMIDialog
      variant={props.isEdit ? 'edit' : 'add'}
      title={formTitle}
      onClose={closeDialog}
      loading={isLoading}
      onActionBtnClick={handleSubmit(onSubmit, onError)}>
      <Stack component="form">
        <AppField
          label="Question"
          multiline
          rows={4}
          {...register('question')}
          error={hasError('question')}
          helperText={getError('question')}
        />
        <AppField
          label="Answer"
          multiline
          rows={4}
          {...register('answer')}
          error={hasError('answer')}
          helperText={getError('answer')}
        />
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <AppSelectField
              label="Category"
              options={chatbotCategories}
              {...field}
            />
          )}
        />
      </Stack>
    </AMIDialog>
  );
};

export default FAQForm;
