import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TablePagination from '@mui/material/TablePagination';
import AMITable from '../../../components/common/AMITable';
import AMITableHeading from '../../../components/common/AMITableHeading';
import AMITableBody from '../../../components/common/AMITableBody';
import libFeedbackColumns from './libFeedbackColumn';
import { paginate, sort } from '../../../utils/helpers';
import { TableColumn, SortColumn, TableOwnProps } from '../../../utils/types';
import { PaperTableContainer } from '../../../components/common/AppContainersLib';
import { selectModifiedlibFeedbacks } from '../../../store/library/feedback/selectors';

const LibFeedbackTable = ({
  rowsPerPage,
  searchKeyword
}: TableOwnProps): JSX.Element => {
  const [page, setPage] = React.useState(0);
  const [sortColumn, setSortColumn] = useState<SortColumn>({
    path: 'index',
    order: 'asc'
  });

  const data = useSelector(selectModifiedlibFeedbacks);
  const columns = React.useMemo<TableColumn[]>(() => libFeedbackColumns, []);

  useEffect(() => {
    if (searchKeyword && page !== 0) setPage(0);
  }, [page, searchKeyword, setPage]);

  // sort handler
  const handleRequestSort = (sortCol: SortColumn) => {
    if (!sortCol) return;
    setSortColumn({ ...sortCol });
  };

  // page change handler
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const filteredData = data.filter((d) =>
    d.question.toLowerCase().includes(searchKeyword.toLowerCase())
  );
  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);
  const paginatedData = paginate(sortedData, page, rowsPerPage);

  return (
    <PaperTableContainer elevation={0}>
      <AMITable>
        <AMITableHeading
          columns={columns}
          sortColumn={sortColumn}
          onSort={handleRequestSort}
        />
        <AMITableBody data={paginatedData} columns={columns} />
      </AMITable>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PaperTableContainer>
  );
};

export default LibFeedbackTable;
