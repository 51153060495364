import { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import SidebarLink from './SidebarLink';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { MenuItem } from '../../utils/types';

interface ICollapsibleSidebarItemProps {
  item: MenuItem;
  activeModule: string;
  onClick: () => void;
}

const CollapsibleSidebarItem = (props: ICollapsibleSidebarItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { item, activeModule, onClick } = props;

  useEffect(() => {
    if (activeModule !== item.module) setIsExpanded(false);
  }, [activeModule, item.module]);

  const toggle = () => {
    onClick();
    setIsExpanded((val) => !val);
  };

  return (
    <>
      <ListItemButton
        sx={{
          pl: 3,
          py: 2,
          display: 'flex',
          color: isExpanded ? 'white' : 'inherit'
        }}
        onClick={toggle}>
        <Typography fontSize="13px">{item.label}</Typography>
        {item.subItems && (
          <>
            {isExpanded ? (
              <ExpandLess
                fontSize="small"
                color="inherit"
                sx={{ ml: 'auto' }}
              />
            ) : (
              <ExpandMore
                fontSize="small"
                color="inherit"
                sx={{ ml: 'auto' }}
              />
            )}
          </>
        )}
      </ListItemButton>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subItems &&
            item.subItems.map((subItem) => (
              <SidebarLink
                key={subItem.route}
                label={subItem.label}
                route={subItem.route}
                hasBullet={subItem.hasBullet}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsibleSidebarItem;
