import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { pieUtils } from '../../utils/constants';
import { getCalcFeedbackData } from '../../store/calcFeedback/selectors';
import { FeedbackDataValueType } from '../../store/calcFeedback/types';
import { appColors } from '../../theme';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: pieUtils.labels,
  datasets: [
    {
      label: 'Feedback',
      data: [12, 19, 3, 5, 2],
      backgroundColor: pieUtils.colors,
      borderColor: pieUtils.colors,
      borderWidth: 1,
      hoverOffset: 8
    }
  ]
};

const FeedbackChart = () => {
  const feedbackData = useSelector(getCalcFeedbackData);

  const plugData = (data: FeedbackDataValueType['answer']) => {
    return {
      labels: pieUtils.labels,
      datasets: [
        {
          label: 'Feedback',
          data: [
            data['Very Satisfied'],
            data.Satisfied,
            data.Neutral,
            data.Unsatisfied,
            data['Very Unsatisfied']
          ],
          backgroundColor: pieUtils.colors,
          borderColor: pieUtils.colors,
          borderWidth: 1,
          hoverOffset: 8
        }
      ]
    };
  };

  if (Object.values(feedbackData).length === 0)
    return (
      <Stack mt={2} height={200} justifyContent="center">
        <Typography
          sx={{ fontSize: '22px', color: appColors.lightGray }}
          textAlign="center">
          No feedback data yet.
        </Typography>
      </Stack>
    );

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        {Object.values(feedbackData)
          .slice(0, 2)
          .map((item) => (
            <Stack
              alignItems="center"
              key={item.question}
              mt={1}
              py={2}
              width={300}>
              <Typography textAlign="center">{item.question}</Typography>
              <Box width="80%">
                <Pie
                  data={plugData(item.answer)}
                  redraw
                  options={{
                    layout: { padding: 10 },
                    plugins: {
                      legend: { display: false }
                    }
                  }}
                />
              </Box>
            </Stack>
          ))}
        <Legends />
      </Box>
    </>
  );
};

function Legends() {
  return (
    <Box py={2} ml={8}>
      <Typography mb={1} fontWeight="bold">
        Legend
      </Typography>
      {pieUtils.labels.map((label, idx) => (
        <Box display="flex" alignItems="center" gap={2} key={label}>
          <Box width={40} height={10} bgcolor={pieUtils.colors[idx]}></Box>
          <Typography fontSize={13}>{label}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default FeedbackChart;
