import * as yup from 'yup';

export const libSurveySchema = yup
  .object({
    question: yup.string().required('Question is required.').trim(),
    answerType: yup
      .string()
      .required('Answer Type is required.')
      .oneOf(['Text Box', 'Drop down'])
  })
  .required();
