import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import ContactList from './ContactList';
import FeedbackList from './FeedbackList';
import SurveyList from './SurveyList';

const ContactManagementPage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/contact-list`}>
        <ContactList />
      </Route>
      <Route path={`${path}/survey-list`}>
        <SurveyList />
      </Route>
      <Route path={`${path}/feedback-list`}>
        <FeedbackList />
      </Route>
    </Switch>
  );
};

export default ContactManagementPage;
