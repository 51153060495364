import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableColumn } from '../../../utils/types';
import { IContact } from '../../../store/contact-mngt/contact-list/types';
import ContactNotes from './ContactNotes';
import {
  formatContactNotesDate,
  formatDatePosted
} from '../../../utils/helpers';
import { ContactNote } from '../../../store/contact-mngt/contact-note/types';

const contactListColumns: TableColumn[] = [
  {
    id: 'checkbox-row',
    label: '',
    key: 'contact-list-checkbox',
    sortable: false,
    dataCellType: 'checkbox'
  },
  {
    id: 'collapse-row',
    label: '',
    key: 'contact-list-collpase',
    sortable: false,
    dataCellType: 'collapse',
    collapseContent: (item: IContact) => <ContactNotes contact={item} />
  },
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  {
    id: 'email',
    label: 'Email',
    property: 'email',
    sortable: true
  },
  {
    id: 'name',
    label: 'Name',
    property: 'name',
    sortable: true
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    property: 'jobTitle',
    sortable: true
  },
  {
    id: 'companyName',
    label: 'Company Name',
    property: 'companyName',
    sortable: true
  },
  {
    id: 'location',
    label: 'Location',
    property: 'location',
    sortable: true
  },
  {
    id: 'dateRegistered',
    label: 'Date Registered',
    key: 'dateRegistered',
    sortable: true,
    content: (item: IContact) => formatDatePosted(item.dateRegistered)
  },
  {
    id: 'status',
    label: 'Status',
    property: 'status',
    sortable: true
  },
  {
    id: 'registrationType',
    label: 'Registration Type',
    property: 'registrationType',
    sortable: true
  },
  {
    id: 'actions',
    label: 'Action',
    key: 'contact-list-actions',
    sortable: false,
    content: (item: IContact) => (
      <ButtonGroup
        disableElevation
        aria-label="contact list actions"
        size="small"
        sx={{ overflow: 'hidden' }}>
        <Button
          color="info"
          sx={{
            paddingX: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          component={Link}
          to={`/contact/contact-list/edit/${item.id}`}>
          <EditIcon fontSize="small" />
        </Button>
        <Button
          color="error"
          sx={{
            paddingX: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          component={Link}
          to={`/contact/contact-list/delete/${item.id}`}>
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    )
  }
];

export const contactNotesColumns: TableColumn[] = [
  {
    id: 'dateCreated',
    label: 'Date & Time',
    property: 'dateCreated',
    sortable: true,
    width: 270,
    align: 'left',
    content: (contactNote: ContactNote) =>
      formatContactNotesDate(contactNote.dateCreated)
  },
  {
    id: 'notes',
    label: 'Notes',
    property: 'note',
    // sortable: true,
    align: 'left'
  }
];

export default contactListColumns;
