import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AMIDialog from '../../../components/common/AMIDialog';
import { deleteLibSurvey } from '../../../store/library/survey/actions';
import { IDParams } from '../../../utils/types';

const LibSurveyDeleteModal = () => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const closeDialog = () => history.push('/library/survey');

  const handleDelete = () => dispatch(deleteLibSurvey(id, closeDialog));

  return (
    <AMIDialog
      variant="delete"
      title="Delete"
      actionButtonText="Delete"
      onClose={closeDialog}
      onActionBtnClick={handleDelete}>
      <AMIDialog.DeleteMessage message="Do you want to delete this record?" />
    </AMIDialog>
  );
};

export default LibSurveyDeleteModal;
