import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import AMIDialog from '../../../components/common/AMIDialog';
import {
  AppField,
  AppSelectField
} from '../../../components/common/AppFormsLib';
import {
  ContactBase,
  contactFormInitialValues
} from '../../../store/contact-mngt/contact-list/types';
import { contactSchema } from './contactSchema';
import { showErrorSnackbar } from '../../../store/system/actions';
import { contactLocationOptions } from '../../../utils/constants';
import {
  createContact,
  updateContact
} from '../../../store/contact-mngt/contact-list/actions';
import {
  getContactById,
  selectContactEmails
} from '../../../store/contact-mngt/contact-list/selectors';
import { IDParams } from '../../../utils/types';

interface ContactFormProps {
  isEdit?: boolean;
}

const ContactForm = (props: ContactFormProps) => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const contactToEdit = useSelector(getContactById(id));
  const contactEmails = useSelector(selectContactEmails);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<ContactBase>({
    resolver: yupResolver(contactSchema),
    defaultValues: contactToEdit
      ? {
          email: contactToEdit.email,
          jobTitle: contactToEdit.jobTitle,
          companyName: contactToEdit.companyName,
          location: contactToEdit.location,
          status: contactToEdit.status
        }
      : contactFormInitialValues
  });

  useEffect(() => {
    if (id && contactToEdit) {
      const { email, jobTitle, companyName, location, name, status } =
        contactToEdit;
      setValue('email', email);
      setValue('name', name);
      setValue('jobTitle', jobTitle);
      setValue('companyName', companyName);
      setValue('location', location);
      setValue('status', status);
    }
  }, [contactToEdit, id, setValue]);

  const closeDialog = () => history.push('/contact/contact-list');

  const formTitle = props.isEdit ? 'Edit Contact' : 'Add Contact';

  const onSubmit = (contact: ContactBase) => {
    // check here if email is already in use
    const emailExists = contactEmails.includes(contact.email) && !props.isEdit;

    const emailExistsUpdate =
      contactEmails.includes(contact.email) &&
      contact.email !== contactToEdit?.email &&
      props.isEdit;

    if (emailExists || emailExistsUpdate) {
      setError('email', { message: 'The provided email is already in use.' });
      return;
    }

    if (!props.isEdit) return dispatch(createContact(contact, closeDialog));

    dispatch(updateContact(id, contact, closeDialog));
  };

  function onError(err: typeof errors) {
    dispatch(showErrorSnackbar('Please check required field(s)'));
  }

  const hasError = (inputName: keyof ContactBase) =>
    errors[inputName] ? true : false;

  const getError = (inputName: keyof ContactBase) => errors[inputName]?.message;

  return (
    <AMIDialog
      variant={props.isEdit ? 'edit' : 'add'}
      title={formTitle}
      onClose={closeDialog}
      actionButtonType="submit"
      actionBtnDisabled={isSubmitting}
      onActionBtnClick={handleSubmit(onSubmit, onError)}>
      <Stack component="form" aria-disabled={isSubmitting}>
        <AppField
          label="Email"
          type="email"
          {...register('email')}
          error={hasError('email')}
          helperText={getError('email')}
        />
        <AppField label="Name" {...register('name')} />
        <AppField label="Job Title" {...register('jobTitle')} />
        <AppField label="Company name" {...register('companyName')} />
        <Controller
          control={control}
          name="location"
          render={({ field }) => (
            <AppSelectField
              label="Location"
              placeholder="Select location"
              options={contactLocationOptions}
              {...field}
            />
          )}
        />
        {props.isEdit && (
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <FormControl>
                <Typography
                  component="label"
                  fontSize={14}
                  htmlFor="email"
                  mb="3px"
                  id="contact-status-radio-buttons-label">
                  Status
                </Typography>
                <RadioGroup
                  aria-labelledby="contact-status-radio-buttons-label"
                  {...field}>
                  <FormControlLabel
                    value="Registered"
                    control={<Radio size="small" />}
                    label="Registered"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }}
                  />
                  <FormControlLabel
                    value="Contacted"
                    control={<Radio size="small" sx={{ fontSize: 14 }} />}
                    label="Contacted"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        )}
      </Stack>
    </AMIDialog>
  );
};

export default ContactForm;
