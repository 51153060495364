import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { PasswordGuidelines } from '../../components/common/PasswordGuidelines';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  getAuthEmail,
  getAuthFirstName,
  getAuthLastName,
  getIsAuthLoading
} from '../../store/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../store/auth/actions';
import { getSystemSnackbar } from '../../store/system/selectors';
import AMIAlert from '../../components/common/AMIAlert';
import AMILoadingIndicator from '../../components/common/AMILoadingIndicator';
import { ProfilePasswordFieldBox } from './ProfileField';
import { showErrorSnackbar } from '../../store/system/actions';
import PasswordField from '../../components/common/PasswordField';

type Inputs = {
  password: string;
  repeatPassword: string;
  oldPassword: string;
};

const validationSchema = yup.object({
  password: yup
    .string()
    .required('This field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,36}$)/,
      'Must follow the guidelines on the right.'
    ),
  repeatPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
  oldPassword: yup.string().required('This field is required')
});

const ProfileSettings = (): JSX.Element => {
  const email = useSelector(getAuthEmail) as string;
  const firstName = useSelector(getAuthFirstName) as string;
  const lastName = useSelector(getAuthLastName) as string;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: { password: '', repeatPassword: '', oldPassword: '' }
  });

  const snackbar = useSelector(getSystemSnackbar);
  const isLoading = useSelector(getIsAuthLoading);
  const history = useHistory();

  const hasError = (inputName: keyof Inputs) =>
    errors[inputName] ? true : false;
  const getError = (inputName: keyof Inputs) => errors[inputName]?.message;

  const onSubmit = (values: Inputs) => {
    const { password, repeatPassword, oldPassword } = values;
    if (password.includes(firstName) || password.includes(lastName)) {
      setError('password', {
        message: 'Passwords must NOT include the user’s first OR last name.'
      });

      return;
    }
    dispatch(
      changePassword(email, password, repeatPassword, oldPassword, reset)
    );
  };

  const onError = (err: typeof errors) => {
    dispatch(showErrorSnackbar('Please check required fields'));
  };

  function goToDashboard() {
    history.push('/');
  }
  return (
    <React.Fragment>
      <AMILoadingIndicator show={isLoading} />
      <Grid container padding="20px 10px" gap={2}>
        <Grid item xs={6} container gap={2}>
          <ProfilePasswordFieldBox label="Old Password">
            <PasswordField
              id="oldPassword"
              type="password"
              fullWidth
              error={hasError('oldPassword')}
              helperText={getError('oldPassword')}
              {...register('oldPassword')}
            />
          </ProfilePasswordFieldBox>
          <ProfilePasswordFieldBox label="New Password">
            <PasswordField
              id="password"
              type="password"
              fullWidth
              error={hasError('password')}
              helperText={getError('password')}
              {...register('password')}
            />
          </ProfilePasswordFieldBox>
          <ProfilePasswordFieldBox label="Confirm Password">
            <PasswordField
              id="confirm-password"
              type="password"
              fullWidth
              error={hasError('repeatPassword')}
              helperText={getError('repeatPassword')}
              {...register('repeatPassword')}
            />
          </ProfilePasswordFieldBox>

          <Stack direction="row" spacing={2} ml="auto">
            <Button onClick={handleSubmit(onSubmit, onError)}>Save</Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={goToDashboard}>
              Cancel
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={5}
          ml="auto"
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: 'divider',
            padding: '16px'
          }}>
          <PasswordGuidelines />
        </Grid>
      </Grid>
      {snackbar && (
        <AMIAlert
          message={snackbar?.message as string}
          open={snackbar?.open}
          type={snackbar?.type}
          autoHideDuration={3000}
          onClose={() => {}}
        />
      )}
    </React.Fragment>
  );
};

export default ProfileSettings;
