import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import { appColors, appSizes } from '../../theme';
import { useSelector } from 'react-redux';
import { getSystemSidebarState } from '../../store/system/selectors';

interface IMainProps {
  children: ReactNode;
}

const AppMain = ({ children }: IMainProps): JSX.Element => {
  const isSidebarHidden = useSelector(getSystemSidebarState);

  const translateXTo = !isSidebarHidden ? appSizes.drawerWidth.expanded : 0;

  return (
    <Stack
      component="main"
      sx={{
        height: '100vh',
        width: `calc(100vw - ${translateXTo}px)`, // will make automatic
        transform: `translateX(${translateXTo}px)`,
        transition: 'transform 0.3s',
        backfaceVisibility: 'hidden'
      }}>
      {children}
    </Stack>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bgcolor={appColors.offWhite}
      boxSizing="border-box"
      mt="48px"
      pb={2}
      px={2}
      flex={1}>
      {children}
    </Stack>
  );
};

AppMain.Container = Container;

export default AppMain;
