import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AMIDialog from '../../../components/common/AMIDialog';
import { deleteFAQ } from '../../../store/library/FAQ/actions';
import { IDParams } from '../../../utils/types';

const FAQDeleteModal = () => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const closeDialog = () => history.push('/library/faq');

  const handleDelete = () => dispatch(deleteFAQ(id, closeDialog));

  return (
    <AMIDialog
      variant="delete"
      title="Delete"
      actionButtonText="Delete"
      onClose={closeDialog}
      onActionBtnClick={handleDelete}>
      <AMIDialog.DeleteMessage message="Do you want to delete this record?" />
    </AMIDialog>
  );
};

export default FAQDeleteModal;
