import { axiosInstance } from '../../../utils/api';
import { apiEndpoints } from '../../../utils/constants';
import errorHandler from '../../../utils/errorHandler';
import { AppThunk } from '../../index';
import { showSuccessSnackbar } from '../../system/actions';
import { Status } from '../../system/types';
import {
  ADD_FAQ,
  DELETE_FAQ,
  FAQAction,
  FAQBase,
  IFAQ,
  SET_FAQ_DATA,
  SET_FAQ_STATUS,
  UPDATE_FAQ
} from './types';

// SYNC ACTIONS
export const setFAQStatus = (status: Status): FAQAction => ({
  type: SET_FAQ_STATUS,
  payload: status
});

export const setFAQData = (data: IFAQ[]): FAQAction => ({
  type: SET_FAQ_DATA,
  payload: data
});

export const addFAQ = (newFAQ: IFAQ): FAQAction => ({
  type: ADD_FAQ,
  payload: { newFAQ }
});

export const updateFAQSync = (id: string, updatedFAQ: FAQBase): FAQAction => ({
  type: UPDATE_FAQ,
  payload: { id, updatedFAQ }
});

export const deleteFAQSync = (id: string): FAQAction => ({
  type: DELETE_FAQ,
  payload: { id }
});

// ASYNC ACTIONS

/**
 *
 * @description Fetches the FAQ list from the database
 */
export const fetchFAQ = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setFAQStatus('loading'));

    const response = await axiosInstance.request({ url: apiEndpoints.faq });

    dispatch(setFAQData(response.data));
  } catch (err: any) {
    dispatch(setFAQStatus('error'));
    errorHandler(err, dispatch);
  }
};

/**
 *
 * @param faq the new FAQ
 * @description Creates a new FAQ through a POST request to the server
 */
export const createFAQ =
  (faq: FAQBase, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFAQStatus('loading'));

      const response = await axiosInstance.request({
        method: 'POST',
        data: faq,
        url: apiEndpoints.faq
      });

      dispatch(addFAQ(response.data));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setFAQStatus('error'));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param faq the updated FAQ
 * @description Updates an existing FAQ through a PUT request to the server
 */
export const updateFAQ =
  (id: string, faq: FAQBase, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFAQStatus('loading'));

      await axiosInstance.request({
        method: 'PUT',
        data: faq,
        url: `${apiEndpoints.faq}/${id}`
      });

      dispatch(updateFAQSync(id, faq));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setFAQStatus('error'));
      errorHandler(err, dispatch);
    }
  };

export const deleteFAQ =
  (id: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFAQStatus('loading'));

      await axiosInstance.request({
        method: 'DELETE',
        url: `${apiEndpoints.faq}/${id}`
      });

      dispatch(deleteFAQSync(id));

      dispatch(showSuccessSnackbar('Successfully deleted!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setFAQStatus('error'));
      errorHandler(err.message, dispatch);
    }
  };
