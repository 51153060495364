import TableSortLabel, {
  tableSortLabelClasses
} from '@mui/material/TableSortLabel';
import { styled } from '@mui/material/styles';
import { appColors } from '../../theme';

const AMITableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  [`&.${tableSortLabelClasses.root}`]: {
    marginLeft: '20px'
  },
  [`&.${tableSortLabelClasses.active}`]: {
    color: theme.palette.common.white
  },
  [`&.${tableSortLabelClasses.root}.inner-table`]: {
    color: appColors.mediumGray,
    marginLeft: 0,

    [`&.${tableSortLabelClasses.icon}`]: {
      color: 'red'
    }
  },
  [`&.${tableSortLabelClasses.active}.inner-table`]: {
    color: appColors.mediumGray
  }
}));

export default AMITableSortLabel;
