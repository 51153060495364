export interface Feedback {
  id?: string;
  question: string;
}

export const libFeedbackFormInitialValues: Feedback = {
  question: ''
};

export interface LibFeedbackState {
  data: Feedback[];
  status: 'idle' | 'loading' | 'success' | 'failed';
  error: string | null | undefined;
}

export const CALL_LIB_FEEDBACK_API = 'call_lib_feedback_api';
export const CALL_LIB_FEEDBACK_API_SUCCESS = 'call_lib_feedback_api_success';
export const CALL_LIB_FEEDBACK_API_FAILED = 'call_lib_feedback_api_failed';
export const SET_LIB_FEEDBACK = 'set_lib_feedback';
export const ADD_LIB_FEEDBACK = 'add_lib_feedback';
export const UPDATE_LIB_FEEDBACK = 'update_lib_feedback';
export const DELETE_LIB_FEEDBACK = 'delete_lib_feedback';

export interface CallLibFeedbackApiAction {
  type: typeof CALL_LIB_FEEDBACK_API;
}

export interface CallLibFeedbackApiSuccessAction {
  type: typeof CALL_LIB_FEEDBACK_API_SUCCESS;
}

export interface CallLibFeedbackApiFailedAction {
  type: typeof CALL_LIB_FEEDBACK_API_FAILED;
  payload: string | null;
}

export interface SetLibFeedbackAction {
  type: typeof SET_LIB_FEEDBACK;
  payload: Feedback[];
}

export interface AddLibFeedbackAction {
  type: typeof ADD_LIB_FEEDBACK;
  payload: Feedback;
}

export interface UpdateLibFeedbackAction {
  type: typeof UPDATE_LIB_FEEDBACK;
  payload: { id: string; updatedLibFeedback: Feedback };
}

export interface DeleteLibFeedbackAction {
  type: typeof DELETE_LIB_FEEDBACK;
  payload: { id: string };
}

export type LibFeedbackAction =
  | CallLibFeedbackApiAction
  | CallLibFeedbackApiSuccessAction
  | CallLibFeedbackApiFailedAction
  | SetLibFeedbackAction
  | AddLibFeedbackAction
  | UpdateLibFeedbackAction
  | DeleteLibFeedbackAction;
