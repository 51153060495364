import { useSelector } from 'react-redux';
import { getContactState } from '../store/contact-mngt/contact-list/selectors';

export default function useResourcesCount() {
  const contactList = useSelector(getContactState);

  const registeredContacts = contactList.data.filter(
    (i) => i.status === 'Registered'
  ).length;

  const contactedContacts = contactList.data.length - registeredContacts;

  const count = {
    registeredContacts,
    contactedContacts
  };

  return count;
}
