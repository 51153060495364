import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { TableColumn } from '../../../utils/types';
import { User } from '../../../store/contact-mngt/survey-list/types';
import { IContact } from '../../../store/contact-mngt/survey-list/types';
import {
    formatDatePosted
} from '../../../utils/helpers';
import { Link } from 'react-router-dom';

export const userColumns: TableColumn[] = [
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  { id: 'email', label: 'Email', property: 'email', sortable: true },
  {
    id: 'dateCreated', label: 'Date', property: 'dateCreated', sortable: true,
    content: (item: IContact) => formatDatePosted(item.dateCreated)
  },
  {
    id: 'actions',
    label: 'Actions',
    key: 'user-actions',
    sortable: false,
    content: (item: User) => (
      <ButtonGroup
        disableElevation
        aria-label="user actions"
        sx={{ overflow: 'hidden' }}>
        <Button
          color="info"
          sx={{
            paddingX: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          component={Link}
          to={`/contact/survey-list/view/${item.id}`}>
          <PreviewIcon fontSize="small" />
        </Button>
        <Button
          color="error"
          sx={{
            paddingX: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          component={Link}
          to={`/contact/survey-list/delete/${item.id}`}>
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    )
  }
];

export const surveyView: TableColumn[] = [
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  { id: 'question', label: 'Question', property: 'question', sortable: true },
  {
    id: 'answer', label: 'Answer', property: 'answer', sortable: true
  }
];

export default userColumns;
