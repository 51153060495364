import { AppState } from "../../index";
import { orderBy } from 'lodash';

// adds 'index' and 'name' key & value
export const selectModifiedUsers = (state: AppState) =>
orderBy(state.feedbackList.data, ['dateCreated'], ['desc']).map(
  (i, idx) => ({ index: idx + 1, ...i })
);

export const getFeedbackState = (state: AppState) => state.feedbackList;

export const getEmailById = (id: string) => (state: AppState) =>
  state.feedbackList.data.find((c) => c.id === id);