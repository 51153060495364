import { AppThunk } from "../../index";
import { axiosInstance } from "../../../utils/api";
import { apiEndpoints } from "../../../utils/constants";
import errorHandler from "../../../utils/errorHandler";
import { showSuccessSnackbar } from "../../system/actions";
import {
  ADD_USER,
  CALL_USERS_API,
  CALL_USERS_API_FAILED,
  CALL_USERS_API_SUCCESS,
  DELETE_USER,
  SET_USERS,
  UPDATE_USER,
  User,
  UsersAction,
} from "./types";
import { downloadResponseData } from "../../../utils/helpers";
import moment from "moment";

export const callUsersApi = (): UsersAction => {
  return {
    type: CALL_USERS_API,
  };
};

export const callUsersApiSuccess = (): UsersAction => {
  return {
    type: CALL_USERS_API_SUCCESS,
  };
};

export const callUsersApiFailed = (err: string | null): UsersAction => {
  return {
    type: CALL_USERS_API_FAILED,
    payload: err,
  };
};

export function setUsers(users: User[]): UsersAction {
  return {
    type: SET_USERS,
    payload: users,
  };
}

export function addUser(user: User): UsersAction {
  return {
    type: ADD_USER,
    payload: user,
  };
}

export function updateUserSync(id: string, updatedUser: User): UsersAction {
  return {
    type: UPDATE_USER,
    payload: {
      id,
      updatedUser,
    },
  };
}

export function deleteUserSync(id: string): UsersAction {
  return {
    type: DELETE_USER,
    payload: {
      id,
    },
  };
}

// ASYNC ACTIONS

/**
 *
 * @description Fetches all users
 */
export const getUsers = (): AppThunk => async (dispatch) => {
  dispatch(callUsersApi());
  try {
    const response = await axiosInstance.request({ url: apiEndpoints.contactFeedback });
    dispatch(setUsers(response.data));
    dispatch(callUsersApiSuccess());
  } catch (err: any) {
    dispatch(callUsersApiFailed(err.message));
    errorHandler(err, dispatch);
  }
};

export const deleteUser =
  (id: string, callback?: ()=> void): AppThunk =>
  async (dispatch) => {
    dispatch(callUsersApi());
    const _url = `${apiEndpoints.contactFeedback}/${id}`;
    try {
      const response = await axiosInstance.request({
        url: _url,
        method: "DELETE",
      });
      const deletedUserId = response.data;
      dispatch(deleteUserSync(deletedUserId));
      dispatch(callUsersApiSuccess());
      dispatch(showSuccessSnackbar("Successfully deleted!"));
      if (callback) callback();
    } catch (err: any) {
      dispatch(callUsersApiFailed(err.message));
      errorHandler(err, dispatch);
    } 
  };

  export const exportFeedback = (): AppThunk => async (dispatch) => {
    try {
      const response = await axiosInstance.request({
        url: `${apiEndpoints.contactFeedback}/export`,
        responseType: 'blob'
      });
  
      const filename = `Feedback_List_${moment().format('MM-DD-YY')}.xlsx`;
  
      if (response.status === 200 || response.status === 204) {
        downloadResponseData(response.data, filename);
      }
    } catch (err: any) {
      dispatch(callUsersApiFailed('error'));
      errorHandler(err.message, dispatch);
    }
  };