import React, { useState } from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PageTitle from '../../../components/common/PageTitle';
import SearchField from '../../../components/common/SearchField';

import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import UpdateIcon from '@mui/icons-material/RefreshOutlined';

import ContactListTable from './ContactListTable';
import AddButton from '../../../components/common/AddButton';
import ContactForm from './ContactForm';
import ContactDeleteModal from './ContactDeleteModal';
import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../../components/common/AppContainersLib';
import { getContactState } from '../../../store/contact-mngt/contact-list/selectors';
import ContactStatusModal from './ContactStatusModal';
import { exportContacts } from '../../../store/contact-mngt/contact-list/actions';

const ContactListView = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { selectedContacts, data } = useSelector(getContactState);

  const updateButtonDisabled = selectedContacts.length === 0;
  const hasData = data.length > 0;

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleAddClick = () => history.push('/contact/contact-list/create');

  const handleExport = () => dispatch(exportContacts());

  const openUpdateStatusModal = () =>
    history.push('/contact/contact-list/update-status');

  return (
    <React.Fragment>
      <Route path={`${path}/create`}>
        <ContactForm />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <ContactForm isEdit />
      </Route>
      <Route path={`${path}/delete/:id`}>
        <ContactDeleteModal />
      </Route>
      <Route path={`${path}/update-status`}>
        <ContactStatusModal />
      </Route>
      <PageWithTableContainer>
        <PageTitle title="Contact List" />
        <ListViewContainer>
          <ControlsContainer>
            <Box width={200}>
              <SearchField
                searchKeyword={searchKeyword}
                onSearchKeywordChange={handleSearch}
              />
            </Box>
            <Stack ml="auto" direction="row" spacing={1}>
              {hasData && (
                <>
                  <Button
                    aria-label="update contacts"
                    disabled={updateButtonDisabled}
                    onClick={openUpdateStatusModal}
                    startIcon={<UpdateIcon fontSize="small" />}>
                    Update
                  </Button>
                  <Button
                    aria-label="export contacts"
                    onClick={handleExport}
                    startIcon={<DownloadIcon fontSize="small" />}>
                    Export
                  </Button>
                </>
              )}
              <AddButton onClick={handleAddClick} />
            </Stack>
          </ControlsContainer>
          <ContactListTable searchKeyword={searchKeyword} />
        </ListViewContainer>
      </PageWithTableContainer>
    </React.Fragment>
  );
};

export default ContactListView;
