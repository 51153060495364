import { AppThunk } from '..';
import { axiosInstance } from '../../utils/api';
import { apiEndpoints } from '../../utils/constants';
import errorHandler from '../../utils/errorHandler';
import { setCalcFeedbackData } from '../calcFeedback/actions';
import { setContactListData } from '../contact-mngt/contact-list/actions';
import { setContactNotesData } from '../contact-mngt/contact-note/actions';
import { setFAQData } from '../library/FAQ/actions';
import { setLibFeedbacks } from '../library/feedback/actions';
import { setLibSurveyData } from '../library/survey/actions';

import {
  SET_SESSION,
  Session,
  SystemAction,
  Snackbar,
  SET_SNACKBAR,
  SET_SYSTEM_LOADING,
  TOGGLE_SIDEBAR
} from './types';

export function setSession(session: Session): SystemAction {
  return {
    type: SET_SESSION,
    payload: session
  };
}

export function setSnackbar(snackbar: Snackbar | null): SystemAction {
  return {
    type: SET_SNACKBAR,
    payload: snackbar
  };
}

export const setSnackbarAsync =
  (snackbar: Snackbar | null): AppThunk =>
  async (dispatch) => {
    dispatch(setSnackbar(snackbar));
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 3000);
  };

export function toggleSidebar(): SystemAction {
  return {
    type: TOGGLE_SIDEBAR,
    payload: null
  };
}

export const showSuccessSnackbar =
  (message: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      setSnackbar({
        open: true,
        type: 'success',
        message
      })
    );
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 3000);
  };

export const showErrorSnackbar =
  (errorMessage: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      setSnackbar({
        open: true,
        type: 'error',
        message: errorMessage
      })
    );
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 3000);
  };

// used for fetching all resource at once
export const setSystemLoading = (isLoading: boolean): SystemAction => {
  return {
    type: SET_SYSTEM_LOADING,
    payload: isLoading
  };
};

export const fetchAllResources = (): AppThunk => async (dispatch) => {
  dispatch(setSystemLoading(true));
  try {
    const contacts = await axiosInstance.request({
      url: apiEndpoints.contacts
    });
    const faq = await axiosInstance.request({
      url: apiEndpoints.faq
    });

    const libSurveys = await axiosInstance.request({
      url: apiEndpoints.libSurveys
    });

    const libFeedbacks = await axiosInstance.request({
      url: apiEndpoints.feedbacks
    });

    const contactNotes = await axiosInstance.request({
      url: apiEndpoints.contactNotes
    });

    const calcFeedbackResponse = await axiosInstance.request({
      url: apiEndpoints.contactFeedback
    });

    dispatch(setContactListData(contacts.data));
    dispatch(setFAQData(faq.data));
    dispatch(setLibSurveyData(libSurveys.data));
    dispatch(setLibFeedbacks(libFeedbacks.data));
    dispatch(setContactNotesData(contactNotes.data));

    const calcFeedbackData = calcFeedbackResponse.data
      .map((i: any) => i.surveyAnswers)
      .reduce((acc: any, curr: any) => [...acc, ...curr]);
    dispatch(setCalcFeedbackData(calcFeedbackData));
  } catch (err: any) {
    errorHandler(err, dispatch);
  } finally {
    dispatch(setSystemLoading(false));
  }
};
