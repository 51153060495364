import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { appColors } from '../../theme';

type StatType = 'registered' | 'contacted';

const colorMap: Record<StatType, { main: string; dark: string }> = {
  registered: {
    main: appColors.warning,
    dark: appColors.warningDark
  },
  contacted: {
    main: appColors.error,
    dark: appColors.errorDark
  }
};

interface DashboardStatBoxProps {
  value: any;
  label: string;
  type: StatType;
}

const DashboardStatBox = (props: DashboardStatBoxProps) => {
  const { value, label, type } = props;

  const color = colorMap[type];

  return (
    <Stack>
      <Stack bgcolor={color.main} py={2} px={3} color="white">
        <Typography fontSize={42} fontWeight="bold">
          {value}
        </Typography>
        <Typography>{label}</Typography>
      </Stack>
      <Stack bgcolor={color.dark} height={32} />
    </Stack>
  );
};

export default DashboardStatBox;
