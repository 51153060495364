import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import AMITable from '../../../components/common/AMITable';
import AMITableHeading from '../../../components/common/AMITableHeading';
import { TableColumn, TableOwnProps } from '../../../utils/types';
import libSurveyColumns from './libSurveyColumns';

import useTableUtils from '../../../hooks/useTableUtils';
import AMITableBody from '../../../components/common/AMITableBody';
import { PaperTableContainer } from '../../../components/common/AppContainersLib';
import { getTransformedLibSurveyList } from '../../../store/library/survey/selectors';

const LibSurveyTable = ({
  searchKeyword
}: Omit<TableOwnProps, 'rowsPerPage'>) => {
  const {
    page,
    paginate,
    sort,
    sortColumn,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    filterBySearch,
    setPage
  } = useTableUtils({ initialSortBy: 'index' });

  const columns = React.useMemo<TableColumn[]>(() => libSurveyColumns, []);

  const data = useSelector(getTransformedLibSurveyList);

  useEffect(() => {
    if (searchKeyword && page !== 0) setPage(0);
  }, [page, searchKeyword, setPage]);

  const filteredData = searchKeyword
    ? filterBySearch(data, searchKeyword)
    : data;

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);
  const paginatedData = paginate(sortedData, page, rowsPerPage);

  return (
    <PaperTableContainer elevation={0}>
      <AMITable>
        <AMITableHeading
          columns={columns}
          sortColumn={sortColumn}
          onSort={sortHandler}
        />
        <AMITableBody data={paginatedData} columns={columns} />
      </AMITable>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PaperTableContainer>
  );
};

export default LibSurveyTable;
