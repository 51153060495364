import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { getIsAuthenticated } from '../../store/auth/selectors';

const AuthenticatedRoute = (props: RouteProps) => {
  const isLoggedIn = useSelector(getIsAuthenticated);

  if (!isLoggedIn) return <Redirect to="/auth/login" />;

  return <Route {...props} />;
};

export default AuthenticatedRoute;
