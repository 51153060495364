import React from 'react';
import { TypographyProps, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { appColors } from '../../theme';

const textStyles: SxProps = {
  color: appColors.darkGray,
  fontWeight: 'bold'
};

const Label = (props: TypographyProps): JSX.Element => {
  return (
    <Typography {...props} sx={{ ...textStyles, ...props.sx }}>
      {props.children}
    </Typography>
  );
};

export default Label;
