import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';

import AMIDialog from '../../../components/common/AMIDialog';
import {
  AppField,
  AppSelectField
} from '../../../components/common/AppFormsLib';
import { showErrorSnackbar } from '../../../store/system/actions';
import { IDParams } from '../../../utils/types';
import { libSurveyAnswerTypeOptions } from '../../../utils/constants';
import {
  getLibSurveyById,
  getLibSurveyState
} from '../../../store/library/survey/selectors';
import {
  LibSurveyBase,
  libSurveyFormInitialValues
} from '../../../store/library/survey/types';
import { libSurveySchema } from './libSurveySchema';
import DropdownOptionsField from './DropdownOptionsField';
import { useEffect } from 'react';
import {
  createLibSurvey,
  updateLibSurvey
} from '../../../store/library/survey/actions';

interface LibSurveyFormProps {
  isEdit?: boolean;
}

const LibSurveyForm = (props: LibSurveyFormProps) => {
  const history = useHistory();
  const { id } = useParams<IDParams>();
  const dataToEdit = useSelector(getLibSurveyById(id));
  const { status } = useSelector(getLibSurveyState);
  const dispatch = useDispatch();
  const isLoading = status === 'loading';

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm<LibSurveyBase>({
    resolver: yupResolver(libSurveySchema),
    defaultValues: dataToEdit
      ? {
          question: dataToEdit.question,
          answerType: dataToEdit.answerType,
          dropDownItems: dataToEdit.dropDownItems
        }
      : libSurveyFormInitialValues
  });

  const isDropdown = watch('answerType') === 'Drop down';
  const dropdownItems = watch('dropDownItems');

  useEffect(() => {
    if (isDropdown) return;
    setValue('dropDownItems', undefined);
  }, [isDropdown, setValue]);

  const handleAddDropdownItem = (option: string) => {
    if (dropdownItems?.includes(option)) return;
    setValue(
      'dropDownItems',
      dropdownItems ? [...dropdownItems, option] : [option]
    );
  };

  const handleDeleteDropdownItem = (option: string) => {
    const ddItems = dropdownItems?.filter((d) => d !== option);
    setValue('dropDownItems', ddItems);
  };

  const closeDialog = () => history.push('/library/survey');

  const formTitle = props.isEdit ? 'Edit Survey' : 'Add Survey';

  const onSubmit = (values: LibSurveyBase) => {
    const { answerType, dropDownItems } = values;

    if (
      answerType === 'Drop down' &&
      (!dropDownItems || dropDownItems.length === 0)
    )
      return dispatch(
        showErrorSnackbar('Provide options for answer type Dropdown.')
      );

    if (!props.isEdit) return dispatch(createLibSurvey(values, closeDialog));

    dispatch(updateLibSurvey(id, values, closeDialog));
  };

  function onError(err: typeof errors) {
    dispatch(showErrorSnackbar('Please check required field(s)'));
  }

  const hasError = (inputName: keyof LibSurveyBase) =>
    errors[inputName] ? true : false;

  const getError = (inputName: keyof LibSurveyBase) =>
    Array.isArray(errors[inputName])
      ? 'Provide the dropdown options'
      : (errors[inputName] as any)?.message;

  return (
    <AMIDialog
      variant={props.isEdit ? 'edit' : 'add'}
      title={formTitle}
      onClose={closeDialog}
      loading={isLoading}
      onActionBtnClick={handleSubmit(onSubmit, onError)}>
      <Stack component="form" gap={1}>
        <AppField
          label="Question"
          multiline
          rows={4}
          {...register('question')}
          error={hasError('question')}
          helperText={getError('question')}
        />
        <Controller
          control={control}
          name="answerType"
          render={({ field }) => (
            <AppSelectField
              error={hasError('answerType')}
              helperText={getError('answerType')}
              placeholder="Select answer type"
              placeholderColor="#222"
              label="Answer Type"
              options={libSurveyAnswerTypeOptions}
              {...field}
            />
          )}
        />
        {isDropdown && (
          <DropdownOptionsField
            optionList={dropdownItems}
            onAddOption={handleAddDropdownItem}
            onDeleteOption={handleDeleteDropdownItem}
          />
        )}
      </Stack>
    </AMIDialog>
  );
};

export default LibSurveyForm;
