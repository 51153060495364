import * as yup from 'yup';

export type LibFeedbackFormInputs = {
  question: string;
};

export const initialValues: LibFeedbackFormInputs = {
  question: '',
};

export const libFeedbackSchema = yup
  .object({
    question: yup.string().required('Question is required.').trim()
  })
  .required();
