import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { TableColumn } from '../../../utils/types';
import { User } from '../../../store/contact-mngt/feedback-list/types';
import { IContact } from '../../../store/contact-mngt/feedback-list/types';
import {
  formatDatePosted
} from '../../../utils/helpers';
import { Link } from 'react-router-dom';
import { SurveyAnswer } from '../../../store/contact-mngt/feedback-list/types';
import {
  BsFillEmojiHeartEyesFill as VerySatisfied,
  BsEmojiSmileFill as Satisfied,
  BsEmojiNeutralFill as Neutral,
  BsEmojiFrownFill as Unsatisfied,
  BsFillEmojiDizzyFill as VeryUnsatisfied,
  BsQuestionLg as NoAnswer
} from 'react-icons/bs';
import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface EmojiItemProps {
  icon: ReactNode;
  label: string;
}
const EmojiItem = (props: EmojiItemProps) => {
  const { icon, label } = props;
}

const userColumns: TableColumn[] = [
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  { id: 'email', label: 'Email', property: 'email', sortable: true },
  {
    id: 'dateCreated', label: 'Date', property: 'dateCreated', sortable: true,
    content: (item: IContact) => formatDatePosted(item.dateCreated)
  },
  {
    id: 'actions',
    label: 'Actions',
    key: 'user-actions',
    sortable: false,
    content: (item: User) => (
      <ButtonGroup
        disableElevation
        aria-label="user actions"
        sx={{ overflow: 'hidden' }}>
        <Button
          color="info"
          sx={{
            paddingX: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          component={Link}
          to={`/contact/feedback-list/view/${item.id}`}>
          <PreviewIcon fontSize="small" />
        </Button>
        <Button
          color="error"
          sx={{
            paddingX: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          component={Link}
          to={`/contact/feedback-list/delete/${item.id}`}>
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    )
  }
];

export const feedbackView: TableColumn[] = [
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  { id: 'question', label: 'Question', property: 'question', sortable: true },
  {
    id: 'answer', label: 'Answer', property: 'answer', sortable: true,
    content: (item: SurveyAnswer) => {
      switch (item.answer) {
        case 'Very Satisfied': return (
          <Stack alignItems="center">
            {<VerySatisfied fontSize={24} />}
            <Typography color="inherit" fontSize={9} component="span">
              {"Very Satisfied"}
            </Typography>
          </Stack>)
        case 'Satisfied': return (
          <Stack alignItems="center">
            {<Satisfied fontSize={24} />}
            <Typography color="inherit" fontSize={9} component="span">
              {"Satisfied"}
            </Typography>
          </Stack>)
        case 'Neutral': return (
          <Stack alignItems="center">
            {<Neutral fontSize={24} />}
            <Typography color="inherit" fontSize={9} component="span">
              {"Neutral"}
            </Typography>
          </Stack>)
        case 'Unsatisfied': return (
          <Stack alignItems="center">
            {<Unsatisfied fontSize={24} />}
            <Typography color="inherit" fontSize={9} component="span">
              {"Unsatisfied"}
            </Typography>
          </Stack>)
        case 'Very Unsatisfied': return (
          <Stack alignItems="center">
            {<VeryUnsatisfied fontSize={24}/>}
            <Typography color="inherit" fontSize={9} component="span">
              {"Very Unsatisfied"}
            </Typography>
          </Stack>)
        default: return (
          <Stack alignItems="center">
            {<NoAnswer fontSize={24}/>}
            <Typography color="inherit" fontSize={9} component="span">
              {"No Answer"}
            </Typography>
          </Stack>)
      }
    }
  }
];

export default userColumns;