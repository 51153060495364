import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import AMILoadingIndicator from '../../../components/common/AMILoadingIndicator';
import { getLibFeedbacks } from '../../../store/library/feedback/actions';
import UserListView from './LibFeedbackListView';

const LibraryFeedbackPage = () => {
  const { path } = useRouteMatch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLibFeedbacks());
  }, [dispatch]);

  return (
    <React.Fragment>
      <AMILoadingIndicator show={false} />
      <Switch>
        <Route path={path}>
          <UserListView />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default LibraryFeedbackPage;
