import React, { ReactNode } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { appColors } from '../../theme';

type AMITableProps = {
  children: ReactNode;
  height?: number | string;
  sticky?: boolean;
  size?: 'small' | 'medium';
};

const AMITable = ({
  children,
  height,
  sticky = true,
  size = 'medium'
}: AMITableProps): JSX.Element => {
  return (
    <TableContainer
      sx={{
        border: `1px solid ${appColors.offWhite}`,
        height: height ? height : 'calc(100% - 52px)',
        maxHeight: height ? height : 'calc(100% - 52px)',
        overflow: 'auto'
      }} // subject to change
    >
      <Table stickyHeader={sticky} size={size}>
        {children}
      </Table>
    </TableContainer>
  );
};

export default AMITable;
