import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import AMILoadingIndicator from '../../components/common/AMILoadingIndicator';
import { getUsers } from '../../store/users/actions';
import { getUserState } from '../../store/users/selectors';
import UserListView from './UserListView';

const UserManagementPage = () => {
  const { path } = useRouteMatch();
  const { status } = useSelector(getUserState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <React.Fragment>
      <AMILoadingIndicator show={status === 'loading'} />
      <Switch>
        <Route path={path}>
          <UserListView />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default UserManagementPage;
