import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';

import AMIDialog from '../../components/common/AMIDialog';
import { AppField } from '../../components/common/AppFormsLib';
import { User, userFormInitialValues } from '../../store/users/types';
import { userSchema } from './userSchema';
import { showErrorSnackbar } from '../../store/system/actions';
import { IDParams } from '../../utils/types';
import { selectUserById, getUserState } from '../../store/users/selectors';
import { createUser, updateUser } from '../../store/users/actions';
import { useEffect } from 'react';

interface FAQFormProps {
  isEdit?: boolean;
}

const FAQForm = (props: FAQFormProps) => {
  const history = useHistory();
  const { id } = useParams<IDParams>();

  const faqToEdit = useSelector(selectUserById(id));
  const { status } = useSelector(getUserState);
  const dispatch = useDispatch();
  const isLoading = status === 'loading';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<User>({
    resolver: yupResolver(userSchema),
    defaultValues: faqToEdit
      ? {
          firstname: faqToEdit.firstname,
          lastname: faqToEdit.lastname,
          email: faqToEdit.email
        }
      : userFormInitialValues
  });

  useEffect(() => {
    if (id && faqToEdit) {
      setValue('firstname', faqToEdit.firstname);
      setValue('lastname', faqToEdit.lastname);
      setValue('email', faqToEdit.email);
    }
  }, [faqToEdit, id, setValue]);

  const closeDialog = () => history.push('/users');

  const formTitle = props.isEdit ? 'Edit User' : 'Add User';

  const onSubmit = (faq: User) => {
    if (!props.isEdit) return dispatch(createUser(faq, closeDialog));

    dispatch(updateUser(id, faq, faq.email, closeDialog));
  };

  function onError(err: typeof errors) {
    dispatch(showErrorSnackbar('Please check required field(s)'));
  }

  const hasError = (inputName: keyof User) =>
    errors[inputName] ? true : false;

  const getError = (inputName: keyof User) => errors[inputName]?.message;

  return (
    <AMIDialog
      variant={props.isEdit ? 'edit' : 'add'}
      title={formTitle}
      onClose={closeDialog}
      loading={isLoading}
      onActionBtnClick={handleSubmit(onSubmit, onError)}>
      <Stack component="form">
        <AppField
          label="First Name"
          {...register('firstname')}
          error={hasError('firstname')}
          helperText={getError('firstname')}
        />
        <AppField
          label="Last Name"
          multiline
          {...register('lastname')}
          error={hasError('lastname')}
          helperText={getError('lastname')}
        />
        <AppField
          label="Email"
          multiline
          {...register('email')}
          error={hasError('email')}
          helperText={getError('email')}
        />
      </Stack>
    </AMIDialog>
  );
};

export default FAQForm;
