import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AMIDialog from '../../../components/common/AMIDialog';
import { updateContactStatus } from '../../../store/contact-mngt/contact-list/actions';
import { getContactState } from '../../../store/contact-mngt/contact-list/selectors';

const ContactStatusModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedContacts } = useSelector(getContactState);

  const closeDialog = () => history.push('/contact/contact-list');

  const handleStatusUpdate = () =>
    dispatch(updateContactStatus(selectedContacts, closeDialog));

  return (
    <AMIDialog
      variant="status-update"
      title="Update Status"
      actionButtonText="Update"
      onClose={closeDialog}
      onActionBtnClick={handleStatusUpdate}>
      <AMIDialog.UpdateMessage message="Do you want to  update the selected record/s?" />
    </AMIDialog>
  );
};

export default ContactStatusModal;
