import { MenuItem } from './types';

const apiversion = 'v1';

export const apiEndpoints = {
  login: `${apiversion}/login`,
  changePassword: `${apiversion}/change-password`,
  faq: `${apiversion}/faqs`,
  contacts: `${apiversion}/contacts`,
  users: `${apiversion}/users`,
  feedbacks: `${apiversion}/feedbacks`,
  libSurveys: `${apiversion}/surveys`,
  contactNotes: `${apiversion}/contact-notes`,
  contactFeedback: `${apiversion}/contact-feedback`,
  contactSurvey: `${apiversion}/contact-survey`
};

export const menuItems: MenuItem[] = [
  { label: 'Dashboard', route: '/', module: 'dashboard' },
  { label: 'User Management', route: '/users', module: 'users' },
  {
    label: 'Contact Management',
    module: 'contact',
    subItems: [
      {
        label: 'Contact List',
        route: '/contact/contact-list',
        hasBullet: true
      },
      {
        label: 'Survey List',
        route: '/contact/survey-list',
        hasBullet: true
      },
      {
        label: 'Feedback List',
        route: '/contact/feedback-list',
        hasBullet: true
      }
    ]
  },
  {
    label: 'Library',
    module: 'library',
    subItems: [
      { label: 'Chatbot', route: '/library/faq', hasBullet: true },
      { label: 'Survey', route: '/library/survey', hasBullet: true },
      { label: 'Feedback', route: '/library/feedback', hasBullet: true }
    ]
  }
];

const contactLocations = [
  'USA',
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Northwest Territories',
  'Nunavut',
  'Yukon'
];

export const contactLocationOptions = contactLocations.map((i) => ({
  id: i,
  label: i,
  value: i
}));

export const faqAnswerTypeOptions = [
  { id: 'faq', label: 'FAQ', value: 'FAQ' },
  { id: 'chatbot', label: 'Chatbot', value: 'Chatbot' }
];

export const libSurveyAnswerTypeOptions = [
  { id: 'text-box', label: 'Text Box', value: 'Text Box' },
  { id: 'drop-down', label: 'Drop down', value: 'Drop down' }
];

export const chatbotCategories = [
  {
    id: 'CHATBOT::CATEGORY::USING::THE::CALCULATOR',
    label: 'Using the Calculator',
    value: 'Using the Calculator'
  },
  {
    id: 'CHATBOT::CATEGORY::TERM::DEFINITIONS',
    label: 'Term Definitions',
    value: 'Term Definitions'
  },
  {
    id: 'CHATBOT::CATEGORY::RAMSAY::PROJECTS',
    label: 'Ramsay Projects',
    value: 'Ramsay Projects'
  }
];

export const pieUtils = {
  labels: [
    'Very Satisfied ',
    'Satisfied',
    'Neutral',
    'Unsatisfied',
    'Very Unsatisfied'
  ],
  colors: [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
  ]
};
