import React, { useState } from 'react';
import { useRouteMatch, Route, useHistory, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import PageTitle from '../../../components/common/PageTitle';
import SearchField from '../../../components/common/SearchField';
import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../../components/common/AppContainersLib';
import FeedbackInnerTable from './FeedbackInnerTable';
import { Stack } from '@mui/material';
import BackButton from '../../../components/common/BackButton';
import { useSelector } from 'react-redux';
import { IDParams } from '../../../utils/types';
import { getEmailById } from '../../../store/contact-mngt/feedback-list/selectors';

const SurveyListView = () => {
  const [searchKeyword] = useState('');
  const { path } = useRouteMatch();
  const history = useHistory();
  const closeDialog = () => history.push('/contact/feedback-list');
  const { id } = useParams<IDParams>();
  const email = useSelector(getEmailById(id));
  const pageTitle = `${email?.email}`
  return (
    <React.Fragment>
      <PageWithTableContainer>
        <PageTitle title= {pageTitle} />
        <ListViewContainer>
          <ControlsContainer>
          <Stack ml="auto" direction="row" spacing={1}>
              <BackButton onClick={closeDialog}/>
            </Stack>
          </ControlsContainer>
          <FeedbackInnerTable searchKeyword={searchKeyword} rowsPerPage={10} />
        </ListViewContainer>
      </PageWithTableContainer>
    </React.Fragment>
  );
};

export default SurveyListView;
