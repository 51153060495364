import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import TablePagination from '@mui/material/TablePagination';
import AMITable from '../../components/common/AMITable';
import AMITableHeading from '../../components/common/AMITableHeading';
import AMITableBody from '../../components/common/AMITableBody';
import userColumns from './userColumns';
// import { customSort } from '../../utils/helpers';
import { TableColumn, TableOwnProps } from '../../utils/types';
import { selectModifiedUsers } from '../../store/users/selectors';
import { PaperTableContainer } from '../../components/common/AppContainersLib';
import useTableUtils from '../../hooks/useTableUtils';

const UsersTable = ({ searchKeyword }: TableOwnProps): JSX.Element => {
  const {
    page,
    paginate,
    sort,
    sortColumn,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    setPage
  } = useTableUtils({ initialSortBy: 'index' });

  const data = useSelector(selectModifiedUsers, shallowEqual);
  const columns = React.useMemo<TableColumn[]>(() => userColumns, []);

  useEffect(() => {
    if (searchKeyword && page !== 0) setPage(0);
  }, [page, searchKeyword, setPage]);

  const filteredData = data.filter((d) =>
    d.name.toLowerCase().includes(searchKeyword.toLowerCase())
  );
  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);
  // const sortedData = customSort(
  //   filteredData,
  //   sortColumn.path,
  //   sortColumn.order
  // );
  const paginatedData = paginate(sortedData, page, rowsPerPage);

  return (
    <PaperTableContainer elevation={0}>
      <AMITable>
        <AMITableHeading
          columns={columns}
          sortColumn={sortColumn}
          onSort={sortHandler}
        />
        <AMITableBody data={paginatedData} columns={columns} />
      </AMITable>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PaperTableContainer>
  );
};

export default UsersTable;
