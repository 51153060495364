import React from 'react';

import SortIcon from '@mui/icons-material/Sort';

import AMITable from '../../../components/common/AMITable';
import AMITableHeading from '../../../components/common/AMITableHeading';
import AMITableBody from '../../../components/common/AMITableBody';
import { TableColumn } from '../../../utils/types';
import { contactNotesColumns } from './contactListColumns';
import useTableUtils from '../../../hooks/useTableUtils';
import { ContactNote } from '../../../store/contact-mngt/contact-note/types';
import { PaperTableContainer } from '../../../components/common/AppContainersLib';
import useTableContainerWidth from '../../../hooks/useTableContainerWidth';

interface ContactNotesTableProps {
  notesData: ContactNote[];
}

const ContactNotesTable = (props: ContactNotesTableProps) => {
  const { sort, sortColumn, sortHandler } = useTableUtils({
    initialSortBy: 'index'
  });
  const columns = React.useMemo<TableColumn[]>(() => contactNotesColumns, []);

  const width = useTableContainerWidth('contact-list-table');

  const sortedData = sort(props.notesData, sortColumn.path, sortColumn.order);

  return (
    <PaperTableContainer
      elevation={0}
      id="notes-table-container"
      sx={{ height: 200, overflow: 'auto', width }}>
      <AMITable height={190} sticky={false} size="small">
        <AMITableHeading
          className="inner-table"
          columns={columns}
          sortColumn={sortColumn}
          onSort={sortHandler}
          SortIcon={SortIcon}
          rotateIcon={false}
        />
        <AMITableBody data={sortedData} columns={columns} />
      </AMITable>
    </PaperTableContainer>
  );
};

export default ContactNotesTable;
