import { AppState } from '../../index';

import { orderBy } from 'lodash';

export const getContactState = (state: AppState) => state.contactList;

export const getContactById = (id: string) => (state: AppState) =>
  state.contactList.data.find((c) => c.id === id);

export const getTransformedContactList = (state: AppState) =>
  orderBy(state.contactList.data, ['dateRegistered'], ['desc']).map(
    (i, idx) => ({ index: idx + 1, ...i })
  );

export const selectContactEmails = (state: AppState) =>
  state.contactList.data.map((i) => i.email);
