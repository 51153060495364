import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';

import AMIDialog from '../../../components/common/AMIDialog';
import { AppField } from '../../../components/common/AppFormsLib';
import {
  Feedback,
  libFeedbackFormInitialValues
} from '../../../store/library/feedback/types';
import { libFeedbackSchema } from './libFeedbackSchema';
import { showErrorSnackbar } from '../../../store/system/actions';
import { IDParams } from '../../../utils/types';
import {
  selectFeedbackLibraryById,
  selectLibFeedbackState
} from '../../../store/library/feedback/selectors';
import {
  createLibFeedback,
  updateLibFeedback
} from '../../../store/library/feedback/actions';
import { useEffect } from 'react';

interface LibFeedbackFormProps {
  isEdit?: boolean;
}

const LibFeedbackForm = (props: LibFeedbackFormProps) => {
  const history = useHistory();
  const { id } = useParams<IDParams>();

  const libFeedbackToEdit = useSelector(selectFeedbackLibraryById(id));
  const { status } = useSelector(selectLibFeedbackState);
  const dispatch = useDispatch();
  const isLoading = status === 'loading';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<Feedback>({
    resolver: yupResolver(libFeedbackSchema),
    defaultValues: libFeedbackToEdit
      ? {
          question: libFeedbackToEdit.question
        }
      : libFeedbackFormInitialValues
  });

  useEffect(() => {
    if (id && libFeedbackToEdit) {
      setValue('question', libFeedbackToEdit.question);
    }
  }, [id, libFeedbackToEdit, setValue]);

  const closeDialog = () => history.push('/library/feedback');

  const formTitle = props.isEdit
    ? 'Edit Feedback Question'
    : 'Add Feedback Question';

  const onSubmit = (feedback: Feedback) => {
    if (!props.isEdit)
      return dispatch(createLibFeedback(feedback, closeDialog));

    dispatch(updateLibFeedback(id, feedback, closeDialog));
  };

  function onError(err: typeof errors) {
    dispatch(showErrorSnackbar('Please check required field(s)'));
  }

  const hasError = (inputName: keyof Feedback) =>
    errors[inputName] ? true : false;

  const getError = (inputName: keyof Feedback) => errors[inputName]?.message;

  return (
    <AMIDialog
      variant={props.isEdit ? 'edit' : 'add'}
      title={formTitle}
      onClose={closeDialog}
      loading={isLoading}
      onActionBtnClick={handleSubmit(onSubmit, onError)}>
      <Stack component="form">
        <AppField
          label="Question"
          {...register('question')}
          error={hasError('question')}
          helperText={getError('question')}
        />
      </Stack>
    </AMIDialog>
  );
};

export default LibFeedbackForm;
