import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import AMILoadingIndicator from '../../../components/common/AMILoadingIndicator';
import { getContactState } from '../../../store/contact-mngt/contact-list/selectors';
import ContactListView from './ContactListView';

const ContactList = () => {
  const { path } = useRouteMatch();

  const { status } = useSelector(getContactState);

  return (
    <React.Fragment>
      <AMILoadingIndicator show={status === 'loading'} />
      <Switch>
        <Route path={path}>
          <ContactListView />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ContactList;
