import React, { ReactNode } from 'react';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';

import { appColors } from '../../theme';
import backGround from '../../assets/images/ramsay-background.png';

type AuthBackgroundProps = { children?: ReactNode };

const AuthBackground = ({ children }: AuthBackgroundProps) => {
  return (
    <Paper
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: appColors.offWhite,
        backgroundImage: `url(${backGround})`
      }}>
      <Backdrop open />
      {children}
    </Paper>
  );
};

export default AuthBackground;
