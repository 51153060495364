import * as yup from 'yup';

export const faqSchema = yup
  .object({
    question: yup.string().required('Question is required.').trim(),
    answer: yup.string().required('Answer is required.').trim(),
    source: yup.string(),
    category: yup.string()
  })
  .required();
