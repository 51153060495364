import { AppError, Status } from '../../system/types';

// STATE
export type SurveyAnswerType = 'Text Box' | 'Drop down';

export interface LibSurveyBase {
  question: string;
  answerType: SurveyAnswerType | '';
  dropDownItems?: string[];
}

export interface ILibSurvey extends LibSurveyBase {
  id: string;
  dateCreated: Date | string;
  dateUpdated: Date | string;
  userId: string;
  username: string;
}

export interface LibSurveyState {
  data: ILibSurvey[];
  status: Status;
  error: AppError;
}

export const libSurveyFormInitialValues: LibSurveyBase = {
  question: '',
  answerType: ''
};

// ACTION TYPES
export const SET_LIBSURVEY_DATA = 'libSurvey/data/set';
export const SET_LIBSURVEY_STATUS = 'libSurvey/status/set';
export const ADD_LIBSURVEY = 'libSurvey/data/add';
export const UPDATE_LIBSURVEY = 'libSurvey/data/update';
export const DELETE_LIBSURVEY = 'libSurvey/data/delete';

interface SetLibSurveyStatusAction {
  type: typeof SET_LIBSURVEY_STATUS;
  payload: Status;
}

interface SetLibSurveyDataAction {
  type: typeof SET_LIBSURVEY_DATA;
  payload: ILibSurvey[];
}

interface AddLibSurveyAction {
  type: typeof ADD_LIBSURVEY;
  payload: { newLibSurvey: ILibSurvey };
}

interface UpdateLibSurveyAction {
  type: typeof UPDATE_LIBSURVEY;
  payload: { id: string; updatedLibSurvey: Partial<ILibSurvey> };
}

interface DeleteLibSurveAction {
  type: typeof DELETE_LIBSURVEY;
  payload: { id: string };
}

export type LibSurveyAction =
  | SetLibSurveyStatusAction
  | SetLibSurveyDataAction
  | AddLibSurveyAction
  | UpdateLibSurveyAction
  | DeleteLibSurveAction;
