import {
  ADD_LIBSURVEY,
  DELETE_LIBSURVEY,
  LibSurveyAction,
  LibSurveyState,
  SET_LIBSURVEY_DATA,
  SET_LIBSURVEY_STATUS,
  UPDATE_LIBSURVEY
} from './types';

const initialState: LibSurveyState = {
  status: 'idle',
  data: [],
  error: undefined
};

export default function reducer(
  state = initialState,
  action: LibSurveyAction
): LibSurveyState {
  switch (action.type) {
    case SET_LIBSURVEY_STATUS:
      return { ...state, status: action.payload };
    case SET_LIBSURVEY_DATA:
      return { ...state, data: action.payload, status: 'idle' };
    case ADD_LIBSURVEY:
      return {
        ...state,
        data: [action.payload.newLibSurvey, ...state.data],
        status: 'idle'
      };
    case UPDATE_LIBSURVEY:
      return {
        ...state,
        data: state.data.map((i) =>
          i.id === action.payload.id
            ? { ...i, ...action.payload.updatedLibSurvey }
            : i
        ),
        status: 'idle'
      };
    case DELETE_LIBSURVEY:
      return {
        ...state,
        data: state.data.filter((i) => i.id !== action.payload.id),
        status: 'idle'
      };
    default:
      return state;
  }
}
