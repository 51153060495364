import React from 'react';
import { ListViewContainer } from '../../components/common/AppContainersLib';
import PageTitle from '../../components/common/PageTitle';
import ProfileTabs from './ProfileTabs';

const ProfileSection = () => {
  return (
    <React.Fragment>
      <PageTitle title="Profile" />
      <ListViewContainer>
        <ProfileTabs />
      </ListViewContainer>
    </React.Fragment>
  );
};

export default ProfileSection;
