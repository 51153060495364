import { AppState } from '../index';
import { orderBy } from 'lodash';

// adds 'index' and 'name' key & value
export const selectModifiedUsers = (state: AppState) =>
  orderBy(state.users.data, ['dateCreated'], ['desc']).map((u, idx) => ({
    index: idx + 1,
    name: `${u.firstname} ${u.lastname}`,
    ...u
  }));
// state.users.data.map((u, idx) => ({
//   ...u,
//   index: idx + 1,
//   name: `${u.firstname} ${u.lastname}`,
// }));

export const selectUserEmails = (state: AppState) =>
  state.users.data.map((u) => u.email);

export const selectUserById = (id: string) => (state: AppState) =>
  state.users.data.find((u) => u.id === id);

export const isUsersLoading = (state: AppState) =>
  state.users.status === 'loading';

export const getUserState = (state: AppState) => state.users;

export const getUserCount = (state: AppState) => state.users.data.length;
