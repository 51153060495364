import { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Select, { SelectProps } from '@mui/material/Select';

import ArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';

import { SelectOptionType } from '../../utils/types';
import { appColors } from '../../theme';

interface OwnProps {
  label?: string;
}

interface OwnSelectProps {
  label: string;
  options?: SelectOptionType[];
  placeholderColor?: string;
  helperText?: string;
}

type AppFieldProps = OwnProps & Omit<TextFieldProps, 'label'>;
type AppSelectFieldProps = OwnSelectProps & Omit<SelectProps, 'label'>;

export const AppField = forwardRef<HTMLInputElement, AppFieldProps>(
  ({ label, ...rest }, ref) => {
    return (
      <Stack mb={1}>
        {label && (
          <Typography component="label" fontSize={14} htmlFor="email" mb="3px">
            {label}
          </Typography>
        )}
        <TextField
          placeholder="Type here"
          {...rest}
          ref={ref}
          size="small"
          InputProps={{ sx: { fontSize: '14px' } }}
        />
      </Stack>
    );
  }
);

export const AppSelectField = forwardRef<HTMLInputElement, AppSelectFieldProps>(
  ({ label, options, placeholderColor, helperText, ...rest }, ref) => {
    return (
      <FormControl sx={{ mb: 1 }}>
        <Typography component="label" fontSize={14} htmlFor="email" mb="3px">
          {label}
        </Typography>
        <Select
          ref={ref}
          {...rest}
          size="small"
          displayEmpty
          IconComponent={ArrowDownIcon}
          sx={{
            fontSize: 14,
            '& .MuiSvgIcon-root': { color: appColors.primary },
            ...rest.sx
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography
                  component="small"
                  fontSize={14}
                  fontWeight={400}
                  color={appColors.lightGray}>
                  {rest.placeholder}
                </Typography>
              );
            }
            return selected as string;
          }}>
          <MenuItem disabled value="">
            <Typography
              component="small"
              fontSize={14}
              fontWeight={400}
              color={placeholderColor ? placeholderColor : appColors.lightGray}>
              {rest.placeholder}
            </Typography>
          </MenuItem>
          {options &&
            options.map((item) => (
              <MenuItem
                key={item.id}
                value={item.value}
                sx={{ width: '100%', fontSize: 14 }}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        {rest.error && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default AppField;
