import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode } from 'react';

export const appColors = {
  darkestGray: '#1A2225',
  darkGray: '#222D31',
  mediumGray: '#596164',
  lightGray: '#A8A8A8',
  titleGray: '#363637',
  offWhite: '#F5F5F5',
  coolWhite: '#EBEBEB',
  lightRed: '#F0DCE0',
  primary: '#9C1C33',
  success: '#28A745',
  error: '#DD4C39',
  errorDark: '#C94434',
  warning: '#F39C11',
  warningDark: '#DD8E0F',
  inputBorder: 'rgba(0, 0, 0, 0.23)'
};

export const appSizes = {
  drawerWidth: {
    small: 60,
    expanded: 240
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#9C1C33'
    },
    secondary: {
      main: '#A8A8A8'
    },
    success: {
      main: '#28A745',
      dark: '#1E7E34'
    },
    info: {
      main: '#3B8DBC',
      light: '#3675B6'
    },
    error: {
      main: '#DD4C39'
    },
    text: {
      primary: '#222222',
      secondary: '#9a9a9a'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 400
        },
        outlinedSecondary: {
          color: appColors.mediumGray
        }
      }
    }
  }
});

export default function AppThemeProvider({
  children
}: {
  children: ReactNode;
}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
