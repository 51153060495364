import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthPage from './modules/Auth';
import AMIContent from './modules/AMIContent';
import AuthenticatedRoute from './components/common/AuthenticatedRoute';

function App(): JSX.Element {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <AuthenticatedRoute path="/">
            <AMIContent />
          </AuthenticatedRoute>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
