import React from 'react';

import {
  DashboardContainer,
  ListViewContainer
} from '../../components/common/AppContainersLib';
import PageTitle from '../../components/common/PageTitle';
import ContactStatSection from './ContactStatSection';
import FeedbackChart from './FeedbackChart';

const DashboardPage = () => {
  return (
    <DashboardContainer>
      <PageTitle title="Dashboard" />
      <ListViewContainer>
        <ContactStatSection />
        <FeedbackChart />
      </ListViewContainer>
    </DashboardContainer>
  );
};

export default DashboardPage;
