import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddOutlined';
import UpdateIcon from '@mui/icons-material/RefreshOutlined';
import DeleteAlertIcon from '@mui/icons-material/ErrorOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { appColors } from '../../theme';

type DialogType = 'add' | 'edit' | 'delete' | 'status-update';

const dialogIconMap: Record<DialogType, ReactNode> = {
  add: <AddIcon />,
  edit: <EditIcon />,
  delete: <DeleteIcon />,
  'status-update': <UpdateIcon />
};

interface AMIDialogProps {
  onClose?: () => void;
  onActionBtnClick?: () => void;
  children?: ReactNode;
  title: string;
  variant?: DialogType;
  actionButtonText?: string;
  cancelButtonText?: string;
  loading?: boolean;
  loadingText?: string;
  actionBtnDisabled?: boolean;
  actionButtonType?: 'button' | 'submit';
}

const AMIDialog = (props: AMIDialogProps) => {
  const {
    onClose,
    children,
    title,
    variant,
    actionButtonText,
    cancelButtonText,
    onActionBtnClick,
    loading,
    loadingText,
    actionBtnDisabled,
    actionButtonType
  } = props;

  const Icon = variant ? dialogIconMap[variant] : null;

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby={`${title}-alert-dialog-title`}
      aria-describedby="alert-dialog-description"
      sx={{ marginX: '10px' }}
      PaperProps={{ sx: { borderRadius: 0, width: 500 } }}>
      <Stack
        id={`${title}-alert-dialog-title`}
        p={2}
        sx={{ bgcolor: (theme) => theme.palette.primary.main, color: 'white' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {Icon} <Typography>{title}</Typography>
        </Stack>
        <IconButton
          aria-label={`close ${title} modal`}
          onClick={onClose}
          color="inherit"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent dividers>
        <Stack spacing={3} paddingY={2}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{ minWidth: '80px', mr: 1 }}>
          {cancelButtonText ? cancelButtonText : 'Cancel'}
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          type={actionButtonType || 'button'}
          disabled={actionBtnDisabled}
          loadingIndicator={loadingText || 'Saving...'}
          onClick={onActionBtnClick}
          sx={{ minWidth: '80px' }}>
          {actionButtonText ? actionButtonText : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const DeleteMessage = ({ message = 'Delete?' }: { message?: string }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <DeleteAlertIcon color="primary" />
      <Typography color={appColors.darkGray} fontSize={15}>
        {message}
      </Typography>
    </Stack>
  );
};

const UpdateMessage = ({ message = 'Update?' }: { message?: string }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <HelpIcon color="primary" />
      <Typography color={appColors.darkGray} fontSize={15}>
        {message}
      </Typography>
    </Stack>
  );
};

AMIDialog.DeleteMessage = DeleteMessage;
AMIDialog.UpdateMessage = UpdateMessage;

export default AMIDialog;
