import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AMILoadingIndicator from '../components/common/AMILoadingIndicator';
import DashboardPage from './Dashboard';
import UserManagementPage from './UserManagement';
import ContactManagementPage from './ContactManagement';
import ProfileSection from './Profile';
import LibraryPage from './Library';
import AppSidebar from './Layout/AppSidebar';
import AppMain from './Layout/AppMain';
import AppHeader from './Layout/AppHeader';
import AppFooter from './Layout/AppFooter';
import {
  getSystemSnackbar,
  getSystemLoadingState
} from '../store/system/selectors';
import AMIAlert from '../components/common/AMIAlert';
import useFetchResources from '../hooks/useFetchResources';

const AMIContent = (): JSX.Element => {
  // watch for error or success message from SYSTEM SNACKBAR
  const snackbar = useSelector(getSystemSnackbar);

  const systemLoading = useSelector(getSystemLoadingState);

  useFetchResources();

  return (
    <React.Fragment>
      <AppSidebar />
      <AppMain>
        <AppHeader />
        {systemLoading ? (
          <AMILoadingIndicator show={systemLoading} />
        ) : (
          <AppMain.Container>
            <Switch>
              <Route path="/" exact>
                <DashboardPage />
              </Route>
              <Route path="/users">
                <UserManagementPage />
              </Route>
              <Route path="/contact">
                <ContactManagementPage />
              </Route>
              <Route path="/library">
                <LibraryPage />
              </Route>
              <Route path="/profile">
                <ProfileSection />
              </Route>
            </Switch>
          </AppMain.Container>
        )}
        <AppFooter />
      </AppMain>
      {snackbar && (
        <AMIAlert
          message={snackbar?.message as string}
          open={snackbar?.open}
          type={snackbar?.type}
          autoHideDuration={3000}
          onClose={() => {}}
        />
      )}
    </React.Fragment>
  );
};

export default AMIContent;
