import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { appColors } from '../../theme';

const AMITableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '13px'
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: appColors.mediumGray,
    color: theme.palette.common.white,
    fontWeight: 400,
    borderColor: appColors.mediumGray,
    fontSize: '13px',
    backfaceVisibility: 'hidden',
    // textAlign: 'center',
    whiteSpace: 'nowrap',
    padding: '16px 0'
  },
  [`&.${tableCellClasses.head}.inner-table`]: {
    backgroundColor: appColors.coolWhite,
    color: appColors.mediumGray,
    fontWeight: 500,
    padding: '6px 18px',
    borderBottom: 'none',

    '& .MuiTableSortLabel-icon': {
      opacity: 1,
      color: appColors.primary
    }
  }
}));

export const AMIDataCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '13px',
    color: appColors.darkGray,
    whiteSpace: 'nowrap'
  }
}));

export default AMITableCell;
