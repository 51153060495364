import {
  createStore,
  combineReducers,
  applyMiddleware,
  AnyAction
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
// @ts-ignore
import expireReducer from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction } from 'redux-thunk';
import { systemReducers } from './system/reducers';
import { authReducers } from './auth/reducers';
import usersReducer from './users/reducers';
import contactListReducer from './contact-mngt/contact-list/reducer';
import surveyListReducer from './contact-mngt/survey-list/reducer';
import feedbackListReducer from './contact-mngt/feedback-list/reducer';
import faqReducer from './library/FAQ/reducer';
import surveyLibraryReducer from './library/survey/reducer';
import feedbackLibraryReducer from './library/feedback/reducers';
import contactNoteReducer from './contact-mngt/contact-note/reducer';
import calcFeedbackReducer from './calcFeedback/reducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['system'],
  transform: [
    expireReducer('session', {
      expireSeconds: 3599, // this is 1 hr
      expiredState: {},
      autoExpire: true
    })
  ]
};

const loginPersistConfig = {
  key: 'login',
  storage: storage
};

const rootReducer = combineReducers({
  system: persistReducer(rootPersistConfig, systemReducers),
  auth: persistReducer(loginPersistConfig, authReducers),
  users: usersReducer,
  contactList: contactListReducer,
  surveyList: surveyListReducer,
  feedbackList: feedbackListReducer,
  faq: faqReducer,
  surveyLibrary: surveyLibraryReducer,
  feedbackLibrary: feedbackLibraryReducer,
  contactNotes: contactNoteReducer,
  calcFeedback: calcFeedbackReducer
});

const middleware = [thunk];
const middlewareEnchancer = applyMiddleware(...middleware);
export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store =
  process.env.NODE_ENV === 'development'
    ? createStore(persistedReducer, composeWithDevTools(middlewareEnchancer))
    : createStore(persistedReducer, middlewareEnchancer);
export type AppState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void | any, AppState, null, AnyAction>;
export type AppDispatch = typeof store.dispatch;

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
console.log(`REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);

export const persistor = persistStore(store);
