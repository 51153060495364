import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import AMILoadingIndicator from '../../../components/common/AMILoadingIndicator';
import { getFAQState } from '../../../store/library/FAQ/selectors';
import FAQListView from './FAQListView';

const FAQPage = () => {
  const { path } = useRouteMatch();

  const { status } = useSelector(getFAQState);

  return (
    <React.Fragment>
      <AMILoadingIndicator show={status === 'loading'} />
      <Switch>
        <Route path={path}>
          <FAQListView />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default FAQPage;
