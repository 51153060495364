import { AppState } from '../../index';
import { orderBy } from 'lodash';

export const selectLibFeedbackState = (state: AppState) =>
  state.feedbackLibrary;

// adds 'index' and 'name' key & value
export const selectModifiedlibFeedbacks = (state: AppState) =>
  orderBy(state.feedbackLibrary.data, ['dateCreated'], ['desc']).map(
    (f, idx) => ({
      ...f,
      index: idx + 1
    })
  );

export const selectFeedbackLibraryById = (id: string) => (state: AppState) =>
  state.feedbackLibrary.data.find((u) => u.id === id);
