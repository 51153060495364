import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import InputAdornment from '@mui/material/InputAdornment';

type SearchFieldProps = {
  searchKeyword: string;
  onSearchKeywordChange: (value: string) => void;
  placeholder?: string;
  id?: string;
};

const SearchField = ({
  searchKeyword,
  onSearchKeywordChange,
  placeholder = 'Search',
  id
}: SearchFieldProps): JSX.Element => {
  return (
    <TextField
      sx={{ bgcolor: 'white', flex: 1, minWidth: '300px' }}
      size="small"
      id={id}
      placeholder={placeholder}
      value={searchKeyword}
      onChange={(e) => onSearchKeywordChange(e.target.value)}
      InputProps={{
        sx: { fontSize: '14px' },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {searchKeyword && (
              <IconButton edge="end" onClick={() => onSearchKeywordChange('')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchField;
