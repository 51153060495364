import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import AMILoadingIndicator from '../../../components/common/AMILoadingIndicator';
import { getLibSurveyState } from '../../../store/library/survey/selectors';
import LibSurveyListView from './LibSurveyListView';

const LibrarySurveyPage = () => {
  const { path } = useRouteMatch();

  const { status } = useSelector(getLibSurveyState);

  return (
    <React.Fragment>
      <AMILoadingIndicator show={status === 'loading'} />
      <Switch>
        <Route path={path}>
          <LibSurveyListView />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default LibrarySurveyPage;
