import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllResources } from '../store/system/actions';

export default function useFetchResources() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllResources());
  }, [dispatch]);
}
