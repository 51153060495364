import { AppState } from '../../index';
import { orderBy } from 'lodash';

export const getContactNotesState = (state: AppState) => state.contactNotes;

export const getContactNoteByContactId =
  (contactId: string) => (state: AppState) =>
    orderBy(
      state.contactNotes.data.filter((n) => n.contactId === contactId),
      ['dateCreated'],
      ['desc']
    ).map((n, i) => ({ index: i + 1, ...n }));
