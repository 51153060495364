import React, { ReactNode } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AMITableRow from './AMITableRow';

import Typography from '@mui/material/Typography';
import { TableColumn } from '../../utils/types';

interface AMIEnhancedTableBodyProps {
  columns: TableColumn[];
  children: ReactNode;
  dataLength: number;
}

const AMIEnhancedTableBody = ({
  children,
  dataLength,
  columns
}: AMIEnhancedTableBodyProps) => {
  return (
    <TableBody sx={{ minHeight: 300 }}>
      {dataLength === 0 && (
        <AMITableRow>
          <TableCell
            colSpan={columns.length}
            sx={{
              bgcolor: 'white',
              padding: '20px 10px',
              textAlign: 'center'
            }}>
            <Typography color="GrayText">No results found.</Typography>
          </TableCell>
        </AMITableRow>
      )}
      {children}
    </TableBody>
  );
};

export default AMIEnhancedTableBody;
