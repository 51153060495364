import {
  CalcFeebackAction,
  CalcFeedbackState,
  SET_CALC_FEEDBACK_DATA
} from './types';

const initialState: CalcFeedbackState = {
  feedbackData: [],
  surveyData: []
};

const calcFeedbackReducer = (
  state = initialState,
  action: CalcFeebackAction
): CalcFeedbackState => {
  switch (action.type) {
    case SET_CALC_FEEDBACK_DATA:
      return { ...state, feedbackData: action.payload.feedbackData };
    default:
      return state;
  }
};

export default calcFeedbackReducer;
