import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import AMILoadingIndicator from '../../../components/common/AMILoadingIndicator';
import { getUsers } from '../../../store/contact-mngt/feedback-list/actions';
import FeedbackListView from './FeedbackListView';
import FeedbackInnerView from './FeedbackInnerView';

const FeedbackList = () => {
  const { path } = useRouteMatch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <React.Fragment>
      <AMILoadingIndicator show={false} />
      <Switch>
        <Route path={`/contact/feedback-list/view/:id`}>
          <FeedbackInnerView />
        </Route>
        <Route path={path}>
          <FeedbackListView />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default FeedbackList;
