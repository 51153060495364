import { axiosInstance } from '../../../utils/api';
import { apiEndpoints } from '../../../utils/constants';
import errorHandler from '../../../utils/errorHandler';
import { AppThunk } from '../../index';
import { showSuccessSnackbar } from '../../system/actions';
import { Status } from '../../system/types';
import {
  ADD_LIBSURVEY,
  DELETE_LIBSURVEY,
  ILibSurvey,
  LibSurveyAction,
  LibSurveyBase,
  SET_LIBSURVEY_DATA,
  SET_LIBSURVEY_STATUS,
  UPDATE_LIBSURVEY
} from './types';

// SYNC ACTIONS
const setLibSurveyStatus = (status: Status): LibSurveyAction => ({
  type: SET_LIBSURVEY_STATUS,
  payload: status
});

export const setLibSurveyData = (data: ILibSurvey[]): LibSurveyAction => ({
  type: SET_LIBSURVEY_DATA,
  payload: data
});

const addLibSurvey = (newLibSurvey: ILibSurvey): LibSurveyAction => ({
  type: ADD_LIBSURVEY,
  payload: { newLibSurvey }
});

const updateLibSurveySync = (
  id: string,
  updatedLibSurvey: Partial<ILibSurvey>
): LibSurveyAction => ({
  type: UPDATE_LIBSURVEY,
  payload: { id, updatedLibSurvey }
});

export const deleteLibSurveySync = (id: string): LibSurveyAction => ({
  type: DELETE_LIBSURVEY,
  payload: { id }
});

// ASYNC ACTIONS

/**
 *
 * @description Fetches the Library Survey list from the database
 */
export const fetchLibSurvey = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLibSurveyStatus('loading'));

    const response = await axiosInstance.request({
      url: apiEndpoints.libSurveys
    });

    dispatch(setLibSurveyData(response.data));
  } catch (err: any) {
    dispatch(setLibSurveyStatus('error'));
    errorHandler(err, dispatch);
  }
};

/**
 *
 * @param libSurvey the new Library Survey
 * @description Creates a new Library Survey through a POST request to the server
 */
export const createLibSurvey =
  (libSurvey: LibSurveyBase, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLibSurveyStatus('loading'));

      const response = await axiosInstance.request({
        method: 'POST',
        data: libSurvey,
        url: apiEndpoints.libSurveys
      });

      dispatch(addLibSurvey(response.data));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setLibSurveyStatus('error'));
      errorHandler(err, dispatch);
    }
  };

/**
 *
 * @param LibSurvey the updated LibSurvey
 * @description Updates an existing LibSurvey through a PUT request to the server
 */
export const updateLibSurvey =
  (
    id: string,
    libSurvey: Partial<ILibSurvey>,
    callback?: () => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLibSurveyStatus('loading'));

      await axiosInstance.request({
        method: 'PUT',
        data: libSurvey,
        url: `${apiEndpoints.libSurveys}/${id}`
      });

      dispatch(updateLibSurveySync(id, libSurvey));

      dispatch(showSuccessSnackbar('Successfully saved!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setLibSurveyStatus('error'));
      errorHandler(err, dispatch);
    }
  };

export const deleteLibSurvey =
  (id: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLibSurveyStatus('loading'));

      await axiosInstance.request({
        method: 'DELETE',
        url: `${apiEndpoints.libSurveys}/${id}`
      });

      dispatch(deleteLibSurveySync(id));

      dispatch(showSuccessSnackbar('Successfully deleted!'));

      if (callback) callback();
    } catch (err: any) {
      dispatch(setLibSurveyStatus('error'));
      errorHandler(err.message, dispatch);
    }
  };
