import {
  ContactNoteState,
  ContactNotesAction,
  SET_CONTACT_NOTES_DATA,
  SET_CONTACT_NOTES_STATUS,
  ADD_CONTACT_NOTE
} from './types';

const initialState: ContactNoteState = {
  data: [],
  status: 'idle',
  error: undefined
};

const contactNoteReducer = (
  state = initialState,
  action: ContactNotesAction
): ContactNoteState => {
  switch (action.type) {
    case SET_CONTACT_NOTES_STATUS:
      return { ...state, status: action.payload.status };
    case SET_CONTACT_NOTES_DATA:
      return { ...state, data: action.payload.data, status: 'idle' };
    case ADD_CONTACT_NOTE:
      return { ...state, data: [action.payload.newContactNote, ...state.data] };
    default:
      return state;
  }
};

export default contactNoteReducer;
