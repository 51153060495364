import React, { useState } from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PageTitle from '../../../components/common/PageTitle';
import SearchField from '../../../components/common/SearchField';
import AddButton from '../../../components/common/AddButton';

import {
  ControlsContainer,
  ListViewContainer,
  PageWithTableContainer
} from '../../../components/common/AppContainersLib';
import LibSurveyTable from './LibSurveyTable';
import LibSurveyForm from './LibSurveyForm';
import LibSurveyDeleteModal from './LibSurveyDeleteModal';
import { getLibSurveyState } from '../../../store/library/survey/selectors';
import { setSnackbarAsync } from '../../../store/system/actions';

const LibSurveyListView = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const history = useHistory();
  const { path } = useRouteMatch();

  const dispatch = useDispatch();
  const { data } = useSelector(getLibSurveyState);

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleAddClick = () => {
    // if (data.length === 5)
    //   return dispatch(
    //     setSnackbarAsync({
    //       type: 'warning',
    //       message: 'Only 5 questions are allowed',
    //       open: true
    //     })
    //   );
    history.push('/library/survey/create');
  };

  return (
    <React.Fragment>
      <Route path={`${path}/create`}>
        <LibSurveyForm />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <LibSurveyForm isEdit />
      </Route>
      <Route path={`${path}/delete/:id`}>
        <LibSurveyDeleteModal />
      </Route>
      <PageWithTableContainer>
        <PageTitle title="SURVEY" />
        <ListViewContainer>
          <ControlsContainer>
            <Box width={200}>
              <SearchField
                searchKeyword={searchKeyword}
                onSearchKeywordChange={handleSearch}
              />
            </Box>
            <Stack ml="auto" direction="row" spacing={1}>
              <AddButton onClick={handleAddClick} />
            </Stack>
          </ControlsContainer>
          <LibSurveyTable searchKeyword={searchKeyword} />
        </ListViewContainer>
      </PageWithTableContainer>
    </React.Fragment>
  );
};

export default LibSurveyListView;
