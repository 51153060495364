import { useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

import { appColors, appSizes } from '../../theme';
import { menuItems } from '../../utils/constants';
import SidebarLink from './SidebarLink';
import CollapsibleSidebarItem from './CollapsibleSidebarItem';
import { useSelector } from 'react-redux';
import { getSystemSidebarState } from '../../store/system/selectors';

const DrawerBrand = () => {
  return (
    <Stack
      height={48}
      direction="row"
      bgcolor={appColors.primary}
      color="white"
      alignItems="center"
      justifyContent="center">
      <Typography
        sx={{ textTransform: 'uppercase' }}
        fontSize={19}
        fontWeight="bold">
        Ramsay
      </Typography>
      <Typography sx={{ textTransform: 'uppercase' }} fontSize={19}>
        AMI
      </Typography>
    </Stack>
  );
};

const DrawerHeader = () => {
  return (
    <Stack
      height={64}
      bgcolor={appColors.darkestGray}
      color={appColors.mediumGray}
      justifyContent="end"
      pl={3}
      pb={1}>
      <Typography fontSize={11} sx={{ textTransform: 'uppercase' }}>
        Main Navigation
      </Typography>
    </Stack>
  );
};

type Module = 'dashboard' | 'content' | 'library';

const AppSidebar = () => {
  const [activeModule, setActiveModule] = useState<Module>('dashboard');

  const sidebarWidth = appSizes.drawerWidth.expanded;

  const isSidebarHidden = useSelector(getSystemSidebarState);

  const width = isSidebarHidden ? 0 : sidebarWidth;

  const handleItemClick = (moduleName: Module) => setActiveModule(moduleName);

  return (
    <Drawer
      open
      anchor="left"
      variant="persistent"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: appColors.darkGray,
        width: width,
        transition: 'transform 0.3s ease-in',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
          transition: 'transform 0.3s ease-in',
          overflowX: 'hidden'
        }
      }}>
      <DrawerBrand />
      <DrawerHeader />
      <List
        component="nav"
        sx={{
          bgcolor: appColors.darkGray,
          color: appColors.lightGray,
          flex: 1
        }}>
        {menuItems.map((item) => (
          <Box key={item.label}>
            {!item.subItems ? (
              <SidebarLink
                label={item.label}
                route={item.route as string}
                onClick={() => handleItemClick(item.module as Module)}
              />
            ) : (
              <CollapsibleSidebarItem
                item={item}
                activeModule={activeModule}
                onClick={() => handleItemClick(item.module as Module)}
              />
            )}
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default AppSidebar;
