import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableColumn } from '../../utils/types';
import { User } from '../../store/users/types';
import { Link } from 'react-router-dom';

const userColumns: TableColumn[] = [
  {
    id: 'index',
    label: 'No.',
    property: 'index',
    sortable: true,
    width: 30
  },
  {
    id: 'name',
    label: 'Name',
    property: 'name',
    sortable: true
  },
  { id: 'email', label: 'Email', property: 'email', sortable: true },
  {
    id: 'actions',
    label: 'Actions',
    key: 'user-actions',
    sortable: false,
    content: (item: User) => (
      <ButtonGroup
        disableElevation
        aria-label="user actions"
        size="small"
        sx={{ overflow: 'hidden' }}>
        <Button
          color="info"
          sx={{
            paddingX: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          component={Link}
          to={`/users/edit/${item.id}`}>
          <EditIcon fontSize="small" />
        </Button>
        <Button
          color="error"
          sx={{
            paddingX: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          component={Link}
          to={`/users/delete/${item.id}`}>
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    )
  }
];

export default userColumns;
